import styled from 'styled-components'
import Colors from '@colors'
import FontsBrokers from '../../../../../../constants/fonts'
export const Main = styled.main`
  background: ${({ isSuccess }) => (isSuccess ? '#F3F5F9' : '#263C69')};
  min-height: 100vh;
  min-width: 100vw;
  padding: 16px;
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    justify-content: flex-start;
    align-items: flex-start;
  }

  @media only screen and (min-width: 1024px) {
    padding: 16px 48px;
  }
`
export const HeroContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`
export const HeroWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  justify-content: flex-start;

  @media only screen and (min-width: 768px) {
    justify-content: space-between;
  }
`
export const EnterBtnWrapperMobile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-top: 16px;

  @media only screen and (min-width: 768px) {
    display: none;
  }
`

export const EnterBtnWrapperDesktop = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  gap: 8px;

  @media only screen and (min-width: 768px) {
    display: flex;
  }
`

export const EnterQuestion = styled.p`
  font-family: ${FontsBrokers.openSans};
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  color: ${Colors.white};
  text-wrap: nowrap;
`

export const EnterBtn = styled.a`
  border-radius: 4px;
  border: 1px solid ${Colors.white};
  background-color: transparent;
  font-family: ${FontsBrokers.openSans};
  color: ${Colors.white};
  padding: 0 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  cursor: pointer;
  text-decoration: none;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: ${Colors.white};
    color: ${Colors.blue26};
  }
`

export const Hero = styled.button`
  padding: 0px;

  background-color: transparent;
  border: none;

  cursor: pointer;
`

export const Footer = styled.footer`
  font-family: ${FontsBrokers.openSans};
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: ${({ isSuccess }) => (isSuccess ? '#525A66' : '#ffffff')};
  text-align: center;
  display: ${({ isSuccess }) => (isSuccess ? 'block' : 'none')};

  position: absolute;
  margin: 32px;
  bottom: 0px;
`
