import React from 'react'
import { createPortal } from 'react-dom'
import { LoaderIcon } from '../Icons/LoaderIcon'
import styled from 'styled-components'

export const Wrapper = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`

const NewLoader = () => {
  const root = document.getElementById('root')
  const id = Math.floor(Math.random() * (10000 - 1) + 0)
  return createPortal(
    <Wrapper>
      <LoaderIcon />
    </Wrapper>,
    root,
    id,
  )
}

export default NewLoader
