export function useTrackingInfo() {
  const TRACKING_INFO_KEY = '@eemovel:tracking-info'

  function removeEmptyParameters(data) {
    return Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value != null),
    )
  }

  const loadTrackingInfo = () => {
    const info = localStorage.getItem(TRACKING_INFO_KEY)
    const trackingInfo = info ? JSON.parse(info) : null

    if (trackingInfo) {
      const filteredInfo = removeEmptyParameters(trackingInfo)

      return filteredInfo
    }

    return trackingInfo
  }

  const getTrackingInfo = url => {
    const existingTrackingInfo = loadTrackingInfo()

    if (!existingTrackingInfo) {
      const trackingInfo = {
        cf_gclid: url.get('gclid'),
        cf_fbclid: url.get('fbclid'),
        cf_utm_campaign: url?.get('utm_campaign'),
        cf_utm_term: url?.get('utm_term'),
        cf_utm_content: url.get('utm_content'),
        cf_utm_medium: url.get('utm_medium'),
        cf_utm_source: url.get('utm_source'),
        cf_gad_source: url.get('gad_source'),
        cf_utm_query: url.get('utm_query'),
      }

      setTrackingInfo(trackingInfo)
    }
  }

  const setTrackingInfo = info => {
    localStorage.setItem(TRACKING_INFO_KEY, JSON.stringify(info))
  }

  const removeTrackingInfo = () => {
    localStorage.removeItem(TRACKING_INFO_KEY)
  }

  return {
    getTrackingInfo,
    removeTrackingInfo,
    loadTrackingInfo,
  }
}
