import React from 'react'
import { MdErrorOutline } from 'react-icons/md'
import Colors from '@colors'

import * as S from './error-message.styles'

export default function ErrorMessage({ infoError }) {
  return (
    <S.ErrorMessageBox>
      <MdErrorOutline size="16" color={Colors.redFF} />
      <S.ErrorMessage>{infoError}</S.ErrorMessage>
    </S.ErrorMessageBox>
  )
}
