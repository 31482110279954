import axios from 'axios'

import { showToast } from '@services/toast'

const BLOG_URL = 'https://blog.eemovel.com.br/wp-json/wp/v2/posts?_embed'

export const getLastNews = async () => {
  let results = null
  try {
    const response = await axios.get(BLOG_URL, {
      'Accept-Encoding': 'gzip, compress, br',
      'Content-Encoding': 'br',
    })
    if (response?.status === 200 && response?.data) {
      const lastNews = response.data?.slice(0, 4)
      results = lastNews.map(({ id, link, title, _embedded, content }) => ({
        id,
        link,
        title: title?.rendered,
        subtitle: content?.rendered,
        imageUrl: _embedded['wp:featuredmedia'][0]?.source_url,
      }))
    }
  } catch (err) {
    console.error(err)
  }

  return results
}

export const subscribeToNewsletter = async ({ email }) => {
  const EVENT_TYPE = 'CONVERSION'
  const EVENT_FAMILY = 'CDP'
  const IDENTIFIER = 'brand-inscricao-newsletter'

  const CONVERSION_URL = process.env.REACT_APP_CONVERSION_URL
  const API_KEY = process.env.REACT_APP_RD_API_KEY
  try {
    const response = await axios.post(
      CONVERSION_URL,
      {
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
        event_type: EVENT_TYPE,
        event_family: EVENT_FAMILY,
        payload: {
          conversion_identifier: IDENTIFIER,
          email: email,
        },
      },
      {
        params: {
          api_key: API_KEY,
        },
      },
    )

    if (response && response.status === 200) {
      showToast('Inscrito com sucesso!', 'success')
    }
  } catch (err) {
    console.error(err)
    showToast('Algo deu errado ao inscrever no newsletter', 'failure')
  }
}
