import React from 'react'
import './segments.css'
import { FaChevronRight } from 'react-icons/fa'
import { Icons } from '../../assets/svg/icons-index'

import texts from '../../language/landingTexts'
import { Link } from 'react-router-dom'

export default function LandingSegments() {
  return (
    <div className="segments-section">
      <p className="segments-section__title">Segmentos que atendemos</p>
      <div className="segments-section__wrapper">
        {texts.segments.map((segment, index) => (
          <Link
            aria-label={segment.title}
            key={index}
            to={segment.href}
            className="segments-section__item"
          >
            <img width="20" height="20" src={Icons[segment.icon]} alt="icon" />
            <div className="segments-section__item-text-box">
              <h1 className="segments-section__item-title">{segment.title}</h1>
              <p className="segments-section__item-description">
                {segment.description}
              </p>
            </div>
            <FaChevronRight className="segments-section__item-arrow" />
          </Link>
        ))}
      </div>
    </div>
  )
}
