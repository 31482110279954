import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Switch, Route, Redirect } from 'react-router'
import CustomRedirect from '@components/CustomRedirect'
import Spinner from '@components/Loading'
import RegisterPage from '@/pages/Brokers/pages/RegisterPage'
import BrokersTermsOfUse from '@/pages/Brokers/pages/TermsOfUse'

import Landing from '@pages/Landing/index'
const Register = React.lazy(() => import('@pages/Register'))
const RegisterSuccess = React.lazy(() => import('@pages/RegisterSuccess'))
const UseTerms = React.lazy(() => import('@pages/UseTerms'))
const PrivacyPolicy = React.lazy(() => import('@pages/PrivacyPolicy'))
const TermsOfUse = React.lazy(() => import('@pages/TermsOfUse'))
const CookiesPolicy = React.lazy(() => import('@pages/CookiesPolicy'))
const TalkToUs = React.lazy(() => import('../../pages/TalkToUs'))
const Avm = React.lazy(() => import('@pages/Avm'))
const Avr = React.lazy(() => import('@pages/Avr'))
const Eva = React.lazy(() => import('./../../pages/Eva'))
const Valuation = React.lazy(() => import('@pages/Valuation'))
const Location = React.lazy(() => import('@pages/Location'))
const Developer = React.lazy(() => import('@pages/Developer/developer'))
const Brokers = React.lazy(() => import('@pages/Brokers'))
const RevokeData = React.lazy(() => import('@pages/RevokeData'))
const Agro = React.lazy(() => import('@/pages/Agro'))
const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Landing} />
        <React.Suspense fallback={<Spinner></Spinner>}>
          <Route exact path="/valuation" component={Valuation} />
          <Route exact path="/valuation-avm" component={Avm} />
          <Route exact path="/valuation-avr" component={Avr} />
          <Route exact path="/valuation-eva" component={Eva} />
          <Route exact path="/location" component={Location} />
          <Route exact path="/developer" component={Developer} />
          <Route
            exact
            path="/agro"
            render={() => {
              window.location.replace('https://eemovelagro.com.br/')
              return null
            }}
          />
          <Route
            exact
            path="/login"
            render={() => {
              window.location.replace('https://brokers.eemovel.com.br/login')
              return null
            }}
          />

          <Route exact path="/brokers" component={Brokers} />
          <Route exact path="/cadastro" component={TalkToUs} />
          {/* <Route exact path="/cadastro/:category" component={Register} />
          <Route exact path="/cadastro/:discountCode?" component={Register} /> */}
          <Route exact path="/revogar-dados" component={RevokeData} />
          <Route
            exact
            path="/cadastro-completo/:category"
            component={RegisterSuccess}
          />
          <Route
            exact
            path="/cadastro-completo/:discountCode?"
            component={RegisterSuccess}
          />
          <Route
            exact
            path="/politica-de-privacidade"
            component={PrivacyPolicy}
          />
          <Route exact path="/termos-de-uso" component={TermsOfUse} />
          <Route exact path="/politica-de-cookies" component={CookiesPolicy} />
          <Route
            exact
            path="/brokers/termos-de-uso"
            component={BrokersTermsOfUse}
          />
          <Route
            exact
            path="/brokers/cadastro/:success?/:discountCode?/:profileType?"
            component={RegisterPage}
          />
          <Route exact path="/fale-conosco" component={TalkToUs} />
        </React.Suspense>
        <CustomRedirect />
      </Switch>
    </BrowserRouter>
  )
}

export default Routes
