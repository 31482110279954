import { useEffect } from 'react'

import { userInfo } from '@services/login'

import Colors from '@colors'

const Chat = ({ hide, color = Colors.blue3E, routeName }) => {
  useEffect(() => {
    function initFreshChat() {
      window.fcWidget.init({
        token: '5ca62d99-1f69-45a2-9b48-21e90859873c',
        host: 'https://wchat.freshchat.com',
        siteId: routeName,
        config: {
          cssNames: {
            widget: 'custom_fc_frame',
            open: 'custom_fc_open',
            expanded: 'custom_fc_expanded',
          },
          headerProperty: {
            backgroundColor: color,
            hideChatButton: hide,
          },
        },
      })

      window.fcWidget.user.setFirstName(userInfo?.username || null)
      window.fcWidget.user.setEmail(userInfo?.email || null)
      window.fcWidget.user.setPhone(userInfo?.phone || null)
      window.fcWidget.user.setProperties({
        plan: userInfo?.plan || null,
        city: userInfo?.city || null,
        userId: userInfo?.userId || null,
        managerId: userInfo?.managerId || null,
        expiresIn: userInfo?.expiresIn || null,
      })
    }

    function initialize(i, t) {
      let e
      i.getElementById(t)
        ? initFreshChat()
        : (((e = i.createElement('script')).id = t),
          (e.async = !0),
          (e.src = 'https://wchat.freshchat.com/js/widget.js'),
          (e.onload = initFreshChat),
          i.head.appendChild(e))
    }
    function initiateCall() {
      initialize(document, 'Freshdesk Messaging-js-sdk')
    }
    window.addEventListener
      ? window.addEventListener('load', initiateCall, !1)
      : window.attachEvent('load', initiateCall, !1)
    return () =>
      window.removeEventListener
        ? window.addEventListener('load', initiateCall, !1)
        : window.detachEvent('load', initiateCall, !1)
  }, [])
  return null
}

export default Chat
