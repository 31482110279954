import React from 'react'
import './index.css'

const Spinner = () => {
  return (
    <div className="backdrop">
      <span className="custom-loader"></span>
    </div>
  )
}

export default Spinner
