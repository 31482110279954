import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import RegisterPageContext from '../../components/Context/RegisterContext'
import RegisterPageWrapper from './Wrapper/Wrapper'
import logo from '@images/Brokers/logo.svg'
import { changeFavicon } from '@utils/changeFavicon'
import { useTrackingInfo } from '@/hooks/useTrackingInfo'

const RegisterPage = () => {
  const { getTrackingInfo } = useTrackingInfo()
  const { search } = useLocation()
  const query = new URLSearchParams(search)

  useEffect(() => {
    changeFavicon(logo)
  }, [])

  useEffect(() => {
    getTrackingInfo(query)
  }, [getTrackingInfo, query])

  return (
    <RegisterPageContext>
      <RegisterPageWrapper />
    </RegisterPageContext>
  )
}
export default RegisterPage
