export default {
  black: '#000000',
  white: '#FFFFFF',
  whiteF5: '#F5F5F5',
  whiteFC: '#fcfcfc',
  whiteF3: '#F3F5F9',
  whiteF1: '#F1F2F5',
  whiteE4: '#E4EAF4',
  whiteEE: '#EEF0F4',
  whiteFD: '#FDFDFD',
  primary: '#29313E',
  secondary: '#B18935',
  mustardC9: '#C99C3C',
  mustard: '#CE9825',
  grayE4: '#E4E4E4',
  grayD: '#D0D1DC',
  grayA1: '#A1A9B4',
  grayA4: '#a4a4a4',
  grayEA: '#EAEEF7',
  grayE7: '#E7EFFE',
  grayBB: '#BBC1C9',
  gray6F: '#6F7987',
  gray38: '#383939',
  gray2D: '#2d3239',
  grayC3: '#c3c3c3',
  gray45: '#454E5B',
  gray50: '#505252',
  gray71: '#717171',
  gray64: '#646464',
  gray32: '#323232',
  grayC4: '#C4C4C4',
  grayC7: '#C7C7C7',
  green: '#2BD798',
  red: '#FE5054',
  redFF: '#ff1f1f',
  redDC: '#DC3D4B',
  green_lemon: '#49AF3E',
  green9B: '#9BE46D',
  green25: '#25d366',
  green72: '#66D072',
  blue2A: '#2A3751',
  blue26: '#263C69',
  blue3E: '#3E63AD',
  blue3d: '#3d64a8',
  blue2C: '#2C5DBD',
  blue35: '#3570E5',
  blue3F: '#3F64AD',
  blue42: '#4281FF',
  blue3d7: '#3d78df',
  blue59: '#59617F',
  blue78: '#7895ce',
  blue61: '#6192E5',
}
