import React from 'react'

import { PrismicRichText } from '@prismicio/react'
import { useContentFromCMS } from '../../hooks/useContentFromCMS'
import { BrokersLogoBlack } from '../../../../components/Icons/BrokersLogoBlack'
import { formatDate } from '../../utils/format-timestamp'

import * as S from './styles'

export default function BrokersTermsOfUse() {
  const TERMS_OF_USE_CONTENT = 'terms-of-use'
  const { content } = useContentFromCMS(TERMS_OF_USE_CONTENT)

  return (
    <S.Container>
      <S.Header>
        <BrokersLogoBlack />
        <h1>Termos de uso</h1>
        <p>
          (Versão {content?.version}{' '}
          {formatDate(content?.rawContent?.last_update)})
        </p>
      </S.Header>
      <S.Container>
        {content && (
          <S.Content>
            <PrismicRichText
              field={content?.content}
              fallback={<p>Houve um erro ao carregar o conteúdo.</p>}
            />
          </S.Content>
        )}
      </S.Container>
    </S.Container>
  )
}
