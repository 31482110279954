export const TELEPHONE_VALIDATION = /^\([0-9]{2}\) [0-9]?[0-9]{4}-[0-9]{4}$/
export const CITY_VALIDATION = /^[A-z-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]+( [A-z-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]+)*-([A-z-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]{2})$/
export const CPF_CNPJ_VALIDATION = /^([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})$/
export const CEP_VALIDATION = /^[0-9]{5}-[0-9]{3}$/
export const CARD_VALIDATION = /^[0-9]{4}[ ][0-9]{4}[ ][0-9]{4}[ ][0-9]{4}$/
export const EXPIRE_CARD_VALIDATION = /^[0-1]{1}[1-9]{1}[/][1-3]{1}[0-9]{1}$/

export const EXPIRE_CARD_MASK = [/\d/, /\d/, '/', /\d/, /\d/]

export const CARD_MASK = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export const CEP_MASK = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]

export const CPF_MASK = [
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
]

export const CNPJ_MASK = [
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
]

// PHONE_MASK [0] = (99) 99999-9999
// PHONE_MASK [1] = (99) 9999-9999
export const PHONE_MASK = [
  [
    '(',
    /[1-9]/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  [
    '(',
    /[1-9]/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
]

export const GET_PHONE_MASK = raw => {
  if (raw) {
    if (raw.length === 15) return PHONE_MASK[0]
    else return PHONE_MASK[1]
  }
  return PHONE_MASK[1]
}

export const BETWEEN_CPF_CNPJ = raw => {
  if (raw) {
    if (raw.length <= 14) return CPF_MASK
    else return CNPJ_MASK
  }
  return CNPJ_MASK
}
