import styled from 'styled-components'
import { Field, Form } from 'formik'
import Colors from '@colors'
import FontsBrokers from '../../../../../../constants/fonts'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  h1 {
    font-size: 21px;
    font-weight: 700;
    line-height: 150%;
    color: ${Colors.white};
    font-family: ${FontsBrokers.openSans};
    margin-top: 38px;
  }

  h2 {
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    color: ${Colors.white};
    font-family: ${FontsBrokers.openSans};
    margin-top: 8px;
    margin-bottom: 32px;
  }

  @media only screen and (min-width: 768px) {
    margin: 0 auto;
    width: 398px;

    h1 {
      margin-top: 68px;
    }
  }
`

export const Header = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 16px;
  margin: 64px 16px 36px 16px;
`

export const Title = styled.h2`
  font-size: 38px;
  font-weight: 700;
  line-height: 57px;
  font-family: ${FontsBrokers.openSans};
  color: ${Colors.white};
`

export const Subtitle = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 24px;
  font-family: ${FontsBrokers.openSans};
  color: ${Colors.white};
  text-align: center;
`

export const StepDividorWrapper = styled.div`
  display: flex;
  gap: 4px;
  width: 100%;
`

export const StepDividorBlue = styled.button`
  background: ${Colors.blue78};
  border-radius: 4px;
  width: 100%;
  height: 6px;
  border: none;
  cursor: pointer;
`

export const StepDividorGray = styled.button`
  background: ${({ stepFinish }) =>
    stepFinish ? Colors.blue78 : Colors.grayA4};
  border-radius: 4px;
  width: 100%;
  height: 6px;
  border: none;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`

export const StepDividorGrayTwo = styled.button`
  background: ${({ stepFinish }) =>
    stepFinish ? Colors.blue78 : Colors.grayA4};
  border-radius: 4px;
  width: 100%;
  height: 6px;
  border: none;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`

export const ActingInfo = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  font-family: ${FontsBrokers.openSans};
  color: ${Colors.gray38};
`

export const FormContainer = styled.section``

export const FormGroup = styled(Form)`
  width: 100%;
`

export const FormGroupContent = styled.div`
  border-radius: 8px;
  background: ${Colors.white};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 36px 24px;
  margin-top: 16px;
  width: 100%;
`

export const InfoProfileType = styled.p`
  font-family: ${FontsBrokers.openSans};
  color: ${Colors.gray38};
  font-size: 16px;
`

export const TooltipBtn = styled.button`
  background: transparent;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: 768px) {
    cursor: pointer;
  }
`
export const BoxTooltip = styled.div`
  background: ${Colors.white};
  padding: 16px;
  position: absolute;
  bottom: 40px;
  border-radius: 8px;
  width: 263px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  display: ${({ isTooltipVisible }) => (isTooltipVisible ? 'block' : 'none')};

  p {
    font-family: ${FontsBrokers.openSans};
    color: #303135;
    font-size: 14px;
  }
`

export const ProfileTypeBtnWrapper = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  margin-top: 16px;
`
export const ProfileTypeBtn = styled.button`
  border-radius: 8px;
  border: 1px solid
    ${({ isSelected }) => (isSelected ? Colors.blue3d : Colors.whiteFC)};
  background: ${({ isSelected }) =>
    isSelected ? Colors.grayE7 : 'transparent'};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  height: 74px;
  width: 100%;
  padding: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  color: ${({ isSelected }) => (isSelected ? Colors.blue3E : Colors.gray38)};
  font-family: ${FontsBrokers.openSans};
  font-size: 14px;
  font-weight: ${({ isSelected }) => (isSelected ? '700' : '400')};
  line-height: 150%;

  :active {
    border: 1px solid ${Colors.blue3d};
  }

  svg {
    min-width: 20px;
    height: 20px;
  }
`

export const NextButton = styled.button`
  border-radius: 4px;
  background: ${Colors.blue3E};
  font-family: ${FontsBrokers.openSans};
  color: ${Colors.white};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-top: 35px;
  height: 35px;
  width: 100%;
  border: none;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }

  &:hover {
    background: ${Colors.blue3d7};
  }
`

export const LoginSection = styled.section`
  font-family: ${FontsBrokers.openSans};
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: ${Colors.white};
  text-align: center;
  margin: 4rem 0;
`

export const LoginCTA = styled.a`
  color: #a7c2f1;
  text-decoration: underline;
`

export const RadioGroup = styled.div`
  @media only screen and (min-width: 768px) {
    display: flex;
    border-bottom: 1px solid ${Colors.white};
    gap: 8px;
    grid-column: 1 / 3;
  }
`

export const Label = styled.label`
  font-family: ${FontsBrokers.openSans};
  font-size: 14px;
  color: ${Colors.gray38};
  margin-bottom: ${({ mb }) => (mb ? '4px' : 0)};
  font-weight: 400;
  line-height: 150%;
`

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 16px;
`

export const PrevArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin-bottom: 4px;
  position: relative;
`

export const PrevArrowButton = styled.button`
  background: transparent;
  border: none;
  width: 15px;
  height: 15px;
  padding: 0;
  cursor: pointer;

  svg {
    color: ${Colors.grayBB};
  }

  &:hover {
    background-color: ${Colors.whiteF3};
    border-radius: 50%;
  }
`

export const Input = styled(Field)`
  border: 1px solid ${({ haserrors }) => (haserrors ? 'red' : Colors.grayE4)};
  border-radius: 4px;
  min-height: 2.5rem;
  padding: 0px 8px;
  font-family: ${FontsBrokers.openSans};
  font-size: 14px;
  color: ${Colors.gray38};

  &:hover {
    border: 1px solid #323232;
  }
  &::focus {
    border: 2px solid ${Colors.blue78} !important;
  }

  &::placeholder {
    color: ${Colors.grayA1};
    font-weight: 400;
  }
`

export const ShowEmailErrorInfoBox = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 4px;

  p {
    color: ${Colors.redFF};
    font-family: ${FontsBrokers.openSans};
    -webkit-font-smoothing: antialiased;
    font-size: 12px;
    font-weight: 600;
    line-height: 150%;
  }
`

export const Enter = styled.a`
  color: #3e63ad;
  font-family: ${FontsBrokers.openSans};
  -webkit-font-smoothing: antialiased;
  font-size: 12px;
  font-weight: 600;
  line-height: 150%;
  cursor: pointer;
`

export const HiddenRadio = styled(Field)`
  display: none;
`

export const FakeRadio = styled.span`
  min-width: 18px;
  width: 18px;
  height: 18px;

  display: inline-block;
  position: relative;
  display: grid;
  place-content: center;
  background: rgba(229, 233, 240, 0.5);
  border: 2px solid ${Colors.white};

  border-radius: 50%;
  &::before {
    display: inline-block;
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    transform: ${({ isSelected }) => (isSelected ? 'scale(1)' : 'scale(0)')};
    transition: 120ms transform ease-in-out;
    background-color: ${Colors.white};
  }
`

export const RadioLabel = styled.label`
  font-family: ${FontsBrokers.openSans};
  font-weight: 700;
  line-height: 150%;
  font-size: 14px;
  color: ${Colors.white};

  display: flex;
  align-items: center;
  gap: 4px;

  padding: 16px;
  height: 3rem;

  border: 1px solid ${Colors.white};
  border-radius: ${({ isTop }) =>
    isTop ? '4px 4px 0px 0px' : '0px 0px 4px 4px'};

  @media only screen and (min-width: 768px) {
    border: none;
    padding: 0px;
  }
`

export const CustomCheckbox = styled.input`
  display: none;
  &:after {
    width: 18px;
    height: 18px;
  }

  &:checked {
    background-color: #3d78df;
  }
`

export const FakeCheckbox = styled.span`
  min-width: 18px;
  width: 18px;
  height: 18px;
  border: ${({ hasAccepted }) => (hasAccepted ? 'none' : '1px solid #bbc1c9')};

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ hasAccepted }) =>
    hasAccepted ? '#3d78df' : Colors.white};
  border-radius: 4px;
`
export const Check = styled.span`
  width: 10px;
  height: 10px;
  display: flex;

  svg {
    width: 10px;
    height: 10px;
  }
`

export const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  grid-column: 1 / 3;
  cursor: pointer;
`

export const CheckboxLabel = styled.span`
  font-family: ${FontsBrokers.openSans};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #525a66;
`

export const Link = styled.a`
  text-decoration: underline;
  color: ${Colors.blue3E};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  font-family: ${FontsBrokers.openSans};
`

export const EyeIcon = styled.button`
  padding: 0px;
  position: absolute;
  right: 12px;
  top: 36px;

  background-color: transparent;
  border: none;

  cursor: pointer;
`

export const SuggestionContainer = styled.div`
  border-radius: 4px;
  overflow-y: auto;
  max-height: 150px;
  position: absolute;
  top: 71px;
  z-index: 15;
  background-color: ${Colors.whiteF3};
  width: 100%;
  padding: 0px 4px;
  ::-webkit-scrollbar {
    width: 18px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 100px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border: 5px solid transparent;
    background-clip: content-box;
    background-color: ${Colors.grayC3};
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`

export const SuggestionItem = styled.p`
  height: 33px;
  display: flex;
  align-items: center;
  margin: 0;
  cursor: pointer;
  font-family: ${FontsBrokers.openSans};
  color: ${Colors.gray38};
  font-size: 14px;

  &:hover {
    background-color: #deebff;
  }
`

export const NoCitiesFoundText = styled.p`
  height: 33px;
  display: flex;
  align-items: center;
  margin: 0;
  color: ${Colors.gray38};
  font-size: 14px;
  font-family: ${FontsBrokers.openSans};
`
