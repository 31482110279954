import React from 'react'
import { Redirect, Route } from 'react-router'

export default function CustomRedirect(props) {
  return (
    <Route
      {...props}
      render={renderProps => (
        <Redirect
          to={{
            pathname: '/',
            state: { from: renderProps.location },
          }}
        />
      )}
    />
  )
}
