export const changeTitle = () => {
  switch (window.location.pathname) {
    case '/login':
      return (document.title = 'EEmovel | Brokers')
    case '/brokers':
      return (document.title =
        'EEmovel | Brokers - A plataforma pioneira de captação inteligente de imóveis.')
    case '/agro':
      return (document.title = 'EEmovel | Agro')
    case '/valuation':
      return (document.title = 'EEmovel | Valuation')
    default:
      return (document.title = 'EEmovel')
  }
}
