import React from 'react'

import Routes from '@main/routes'

const App = props => {
  return (
    <main>
      <Routes />
    </main>
  )
}

export default App
