import agro from './agro-logo.svg'
import brokers from './brokers-logo.svg'
import developer from './developer-logo.svg'
import location from './location-logo.svg'
import valuation from './valuation-logo.svg'

export const logos = {
  agro: `<a href="https://eemovel.com.br/agro" title="EEmovel Agro" style="padding: 0;"><img width="55" height="15" src=${agro} alt='agro' /></a><span style="display: none;">EEmovel Agro</span> <br/>`,
  brokers: `<a href="https://eemovel.com.br/brokers" title="EEmovel Brokers" style="padding: 0;"><img width="106" height="15" src=${brokers} alt='brokers' /></a><span style="display: none;">EEmovel Brokers</span> <br/>`,
  developer: `<a href="https://eemovel.com.br/developer" title="EEmovel Developer" style="padding: 0;"><img width="115" height="15" src=${developer} alt='developer' /></a><span style="display: none;">EEmovel Developer</span> <br/>`,
  location: `<a href="https://eemovel.com.br/location" title="EEmovel Location" style="padding: 0;"><img width="100" height="15" src=${location} alt='location' /></a><span style="display: none;">EEmovel Location</span> <br/>`,
  valuation: `<a href="https://eemovel.com.br/valuation" title="EEmovel Valuation" style="padding: 0;"><img width="105" height="15" src=${valuation} alt='valuation' /></a><span style="display: none;">EEmovel Valuation</span> <br/>`,
}
