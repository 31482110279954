import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

const RegisterContext = createContext()

const RegisterPageContext = ({ children }) => {
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const success = query.get('success') === 'true'
  const [isLoading, toggleLoading] = useState(false)

  const dataMemoized = useMemo(
    () => ({
      success,
      isLoading,
    }),
    [isLoading, success],
  )

  const setIsLoading = useCallback(
    value => {
      toggleLoading(value)
    },
    [toggleLoading],
  )
  return (
    <RegisterContext.Provider value={{ ...dataMemoized, setIsLoading }}>
      {children}
    </RegisterContext.Provider>
  )
}

export const useRegisterPage = () => useContext(RegisterContext)

export default RegisterPageContext
