import React, { useState, useRef, useCallback, useEffect } from 'react'
import axios from 'axios'
import { Formik } from 'formik'
import formatPhone from '@utils/formatPhone'
import { debounce } from 'lodash'
import { getCities } from '@services/register'
import { showToast } from '@services/toast'
import { useTrackingInfo } from '@/hooks/useTrackingInfo'
import {
  SignupSchema,
  formatLeadPayload,
  formatPayload,
  getProfileTypeInfo,
  initialValues,
  removeParentheses,
} from './formUtils'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import Eye from '../../../../../../components/Icons/Eye'
import EyeCrossed from '../../../../../../components/Icons/EyeCrossed'
import CheckNew from '../../../../../../components/Icons/CheckNew'
import { MdPerson, MdInfoOutline } from 'react-icons/md'
import { HiUserGroup } from 'react-icons/hi'
import { IoArrowBack } from 'react-icons/io5'
import { useRegisterPage } from '../../../Context/RegisterContext'
import {
  resetPasswordService,
  submitRegister,
} from '../../../../../../services/register'
import Cookies from 'js-cookie'
import {
  REGISTER_EMAIL,
  LEAD_EVENT_NAME,
  FORM_DATA_LAYER,
} from '../../../../utils/register'

import * as S from './styles'
import ErrorMessage from './error-message'

const BrokersRegister = () => {
  const [hasAccepted, toggleAccepted] = useState(false)
  const [isVisible, toggleVisible] = useState(false)
  const [suggestions, setSuggestions] = useState()
  const [isCitySuggestionsVisible, toggleCitySuggestions] = useState(false)
  const [cityId, setCityId] = useState()
  const [showEmailErrorModal, setShowEmailErrorModal] = useState(false)
  const [email, setEmail] = useState('')
  const [suggestionHasBeenClicked, setSuggestionHasBeenClicked] = useState(
    false,
  )
  const [step, setStep] = useState(0)
  const [isTooltipVisible, setTooltipVisible] = useState(false)
  const wrapperRef = useRef()
  const formRef = useRef()
  const { setIsLoading } = useRegisterPage()

  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const discountCode = query.get('discountCode')

  const { loadTrackingInfo, removeTrackingInfo } = useTrackingInfo()

  const eyeIconSize = 14

  const handlePhoneChange = (e, field) => {
    if (e.target.value.length > 15) return
    formRef.current.setFieldValue(field, formatPhone(e.target.value))
  }

  const BROKERS_LOGIN_URL = process.env.REACT_APP_PLATFORM_URL_BROKERS

  const handleCRECI = (e, ProfileType) => {
    const isValue = e.target.value
    const replaceNonDigits = isValue.replaceAll(/\D/g, '')

    formRef.current.setFieldValue('CRECI', replaceNonDigits)
  }

  const getCitySuggestions = async city => {
    if (city !== '') {
      const cities = await getCities(city)

      setSuggestions(cities.map(item => ({ ...item, label: item.name })))
      toggleCitySuggestions(true)
    } else {
      setSuggestions([])
      toggleCitySuggestions(false)
    }
  }

  const debouncedCitySuggestions = debounce(getCitySuggestions, 100)

  const handleClickOutside = useCallback(
    event => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        if (!suggestionHasBeenClicked) {
          toggleCitySuggestions(false)
          formRef.current.setFieldValue('city', '')
          setSuggestionHasBeenClicked(false)
        }
      }
    },
    [suggestionHasBeenClicked],
  )

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [handleClickOutside])

  const sendLeadEvent = async leadPayload => {
    const BASE_URL = process.env.REACT_APP_ACTIVE_CAMPAIGN_INTEGRATION_URL

    try {
      await axios.post(BASE_URL, leadPayload)

      removeTrackingInfo()
    } catch (error) {
      console.error('Error creating contact:', error)
    }
  }

  const handlePrevStep = () => {
    setStep(prevState => prevState - 1)
  }

  const sendLeadEventDropOffs = dataLayerName => {
    window.dataLayer.push({
      event: dataLayerName,
    })
  }

  const handleNextStep = dataLayerName => {
    setStep(prevState => prevState + 1)
    sendLeadEventDropOffs(dataLayerName)
  }

  const handleClickNewProfileType = (newValue, values, setFieldValue) => {
    setFieldValue('ProfileType', newValue)
    if (values.CRECI.length > 3) {
      const { maskEnd } = getProfileTypeInfo(newValue)
      const newCRECI = `${values.CRECI.substring(
        0,
        values.CRECI.length - 2,
      )}${maskEnd}`
      setFieldValue('CRECI', newCRECI)
    }
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    if (values.ProfileType === 'realtor') {
      values.CompanyName = 'Corretor autônomo'
    }
    const utm = Cookies.get('__utmzz')
    const utmData = utm?.split('|')
    let cf_origem = ''
    let cf_midia = ''
    let cf_campanha = ''
    let cf_termo = ''
    let cf_conteudo = ''

    if (window.location.hostname === 'eemovel.com.br') {
      utmData?.forEach(item => {
        let regExp = /=([\s\S]*)$/
        let matches = regExp.exec(item)

        if (matches.input.includes('utmcsr')) {
          cf_origem = removeParentheses(matches[1])
        } else if (matches.input.includes('utmcmd')) {
          cf_midia = removeParentheses(matches[1])
        } else if (matches.input.includes('utmctr')) {
          cf_termo = removeParentheses(matches[1])
        } else if (matches.input.includes('utmcct')) {
          cf_conteudo = removeParentheses(matches[1])
        } else {
          cf_campanha = removeParentheses(matches[1])
        }
      })
    }

    const newValueCreci = `${values.CRECI} - ${
      getProfileTypeInfo(values.ProfileType).maskEnd
    }`

    const newValues = {
      ...values,
      CRECI: newValueCreci,
    }

    const trackingInfo = loadTrackingInfo()

    const formattedValues = formatPayload(newValues)
    const leadPayload = formatLeadPayload(newValues, trackingInfo)
    const registerPayload = { ...formattedValues, city: cityId }

    setIsLoading(true)
    setEmail(values.Email)

    const response = await submitRegister(registerPayload, discountCode)

    const success = response?.status === 200 ? true : false
    const token = response?.data?.token
    const emailError = response?.data?.Message === 'E-mail já está sendo usado.'
    const loginUrl = `${BROKERS_LOGIN_URL}/primeiro-acesso?token=${token}`

    if (emailError) {
      setIsLoading(false)
      setShowEmailErrorModal(true)
      setEmail('')
      setIsLoading(false)
      setSubmitting(false)

      return
    }
    if (success && token) {
      await sendLeadEvent(leadPayload)
      showToast(
        'Conta criada com sucesso! Vamos redirecionar você...',
        'success',
      )

      setTimeout(() => {
        window.location.href = loginUrl
      }, 3000)
    } else {
      sendLeadEventDropOffs(FORM_DATA_LAYER.error)
      showToast(
        response?.response?.data?.Message ||
          'Instabilidade nos servidores, tente novamente em alguns minutos',
        'failure',
      )

      setTimeout(() => {
        window.location.reload()
      }, 2000)
    }

    setEmail('')
    setIsLoading(false)
    setSubmitting(false)
  }
  const handleCityInput = (e, setFieldValue, values) => {
    if (values.HasValidCity) setFieldValue('HasValidCity', false)

    const isValue = e.target.value.toLowerCase()?.trim()
    debouncedCitySuggestions(isValue)
    setFieldValue('City', e.target.value)
  }

  const handleClickSuggestion = (setFieldValue, item) => {
    setSuggestionHasBeenClicked(true)
    toggleCitySuggestions(false)
    setFieldValue('City', item.name)
    setFieldValue('HasValidCity', true)
    setCityId(item.value)
    setSuggestions([])
  }

  const handleResetPassword = async () => {
    const response = await resetPasswordService(email)
    setShowEmailErrorModal(false)
    if (response.status === 200) {
      showToast(
        'Enviamos uma nova senha para o seu email. Confira sua caixa de entrada.',
        'success',
      )
    } else {
      showToast(
        'Houve um erro ao recuperar sua senha. Verifique o e-mail informado no cadastro e tente novamente.',
        'error',
      )
    }
    setIsLoading(false)
    setTimeout(() => {
      window.location.reload()
    }, 3000)
  }

  const handleEmailChange = (e, setFieldValue) => {
    localStorage.removeItem(REGISTER_EMAIL)
    setFieldValue('Email', e.target.value)
    setShowEmailErrorModal(false)
  }

  const handleErrorStepTwo = (errors, touched, values) => {
    const isErrorInputs =
      values.CRECI === '' ||
      values.Name === '' ||
      values.City === '' ||
      values.PhoneNumber === ''

    const isErrorCity = !values.HasValidCity && touched.City && !errors.City

    return isErrorInputs || isErrorCity
  }

  return (
    <S.Wrapper>
      <Formik
        validationSchema={SignupSchema}
        initialValues={{
          ...initialValues,
          Email: localStorage.getItem(REGISTER_EMAIL) || '',
        }}
        innerRef={formRef}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, values, setFieldValue, isSubmitting, dirty }) => (
          <S.FormGroup
            id={
              values.ProfileType === 'realEstateAgency'
                ? 'form-pj-cadastro-brokers'
                : 'form-pf-cadastro-brokers'
            }
          >
            <h1>Cadastro</h1>
            <h2>Complete o cadastro para alavancar suas captações</h2>
            <S.StepDividorWrapper>
              <S.StepDividorBlue
                type="button"
                onClick={() => setStep(0)}
              ></S.StepDividorBlue>
              <S.StepDividorGray
                type="button"
                stepFinish={!!step}
                onClick={() => {
                  setStep(1)
                  sendLeadEventDropOffs(FORM_DATA_LAYER.step1)
                }}
                disabled={values.ProfileType === ''}
              ></S.StepDividorGray>
              <S.StepDividorGrayTwo
                type="button"
                stepFinish={step > 1 ? true : false}
                onClick={() => {
                  setStep(1)
                  sendLeadEventDropOffs(FORM_DATA_LAYER.step2)
                }}
                disabled={handleErrorStepTwo(errors, touched, values) || !dirty}
              ></S.StepDividorGrayTwo>
            </S.StepDividorWrapper>
            <S.FormGroupContent>
              {!step && (
                <>
                  <S.InfoProfileType>
                    Como você descreve sua atuação?
                  </S.InfoProfileType>
                  <S.ProfileTypeBtnWrapper>
                    <S.ProfileTypeBtn
                      type="button"
                      isSelected={values.ProfileType === 'realEstateAgency'}
                      onClick={() =>
                        handleClickNewProfileType(
                          'realEstateAgency',
                          values,
                          setFieldValue,
                        )
                      }
                    >
                      <HiUserGroup />
                      Imobiliária
                    </S.ProfileTypeBtn>
                    <S.ProfileTypeBtn
                      type="button"
                      isSelected={values.ProfileType === 'realtor'}
                      onClick={() =>
                        handleClickNewProfileType(
                          'realtor',
                          values,
                          setFieldValue,
                        )
                      }
                    >
                      <MdPerson />
                      Corretor autônomo
                    </S.ProfileTypeBtn>
                  </S.ProfileTypeBtnWrapper>

                  <S.NextButton
                    type="button"
                    onClick={() => handleNextStep(FORM_DATA_LAYER.step1)}
                    disabled={values.ProfileType === ''}
                  >
                    Avançar
                  </S.NextButton>
                </>
              )}
              {step === 1 && (
                <>
                  <S.InputGroup aria-roledescription="group">
                    <S.PrevArrowWrapper>
                      <S.BoxTooltip isTooltipVisible={isTooltipVisible}>
                        <p>
                          A EEmovel Brokers é exclusiva para corretores ou
                          imobiliárias com registro no CRECI.
                        </p>
                      </S.BoxTooltip>
                      <S.PrevArrowButton type="button" onClick={handlePrevStep}>
                        <IoArrowBack size="15" />
                      </S.PrevArrowButton>
                      <S.Label htmlFor="CRECI">
                        CRECI ({getProfileTypeInfo(values.ProfileType).label})
                      </S.Label>
                      <S.TooltipBtn
                        onMouseEnter={() => setTooltipVisible(true)}
                        onMouseLeave={() => setTooltipVisible(false)}
                        onClick={() => setTooltipVisible(true)}
                        type="button"
                      >
                        <MdInfoOutline size="16" color="#6F7171" />
                      </S.TooltipBtn>
                    </S.PrevArrowWrapper>
                    <S.Input
                      id="CRECI"
                      placeholder="000000"
                      onChange={e => handleCRECI(e, values.ProfileType)}
                      value={values.CRECI}
                      haserrors={errors.CRECI && touched.CRECI}
                    />

                    {errors.CRECI && touched.CRECI && (
                      <ErrorMessage infoError={errors.CRECI} />
                    )}
                  </S.InputGroup>

                  {values.ProfileType !== 'realtor' && (
                    <S.InputGroup aria-roledescription="group">
                      <S.Label mb htmlFor="CompanyName">
                        Nome da imobiliária
                      </S.Label>
                      <S.Input
                        name="CompanyName"
                        id="CompanyName"
                        placeholder="Informe o nome da empresa"
                        haserrors={errors.CompanyName && touched.CompanyName}
                      />

                      {errors.CompanyName && touched.CompanyName && (
                        <ErrorMessage infoError={errors.CompanyName} />
                      )}
                    </S.InputGroup>
                  )}

                  <S.InputGroup aria-roledescription="group">
                    <S.Label mb htmlFor="Name">
                      Nome completo
                    </S.Label>
                    <S.Input
                      name="Name"
                      id="Name"
                      placeholder="Seu nome completo"
                      haserrors={errors.Name && touched.Name}
                    />

                    {errors.Name && touched.Name && (
                      <ErrorMessage infoError={errors.Name} />
                    )}
                  </S.InputGroup>

                  <S.InputGroup
                    aria-roledescription="group"
                    style={{ position: 'relative' }}
                  >
                    <S.Label mb htmlFor="City">
                      Cidade de atuação
                    </S.Label>
                    <S.Input
                      name="City"
                      id="City"
                      placeholder="Selecione a sua cidade principal"
                      autoComplete="new-password"
                      onChange={e => handleCityInput(e, setFieldValue, values)}
                      haserrors={
                        touched.City && (errors.City || !values.HasValidCity)
                      }
                    />
                    {isCitySuggestionsVisible && (
                      <S.SuggestionContainer ref={wrapperRef}>
                        {suggestions.length === 0 ? (
                          <S.NoCitiesFoundText>
                            Nenhuma cidade encontrada
                          </S.NoCitiesFoundText>
                        ) : (
                          suggestions.map(item => (
                            <S.SuggestionItem
                              key={item.value}
                              onClick={() =>
                                handleClickSuggestion(setFieldValue, item)
                              }
                            >
                              {item.name}
                            </S.SuggestionItem>
                          ))
                        )}
                      </S.SuggestionContainer>
                    )}

                    {errors.City && touched.City && (
                      <ErrorMessage infoError={errors.City} />
                    )}
                    {!values.HasValidCity && touched.City && !errors.City && (
                      <ErrorMessage infoError={errors.HasValidCity} />
                    )}
                  </S.InputGroup>

                  <S.InputGroup aria-roledescription="group">
                    <S.Label mb htmlFor="PhoneNumber">
                      Telefone
                    </S.Label>
                    <S.Input
                      value={values.PhoneNumber}
                      id="PhoneNumber"
                      onChange={e => handlePhoneChange(e, 'PhoneNumber')}
                      placeholder="(00) 00000-0000"
                      haserrors={errors.PhoneNumber && touched.PhoneNumber}
                    />

                    {errors.PhoneNumber && touched.PhoneNumber && (
                      <ErrorMessage infoError={errors.PhoneNumber} />
                    )}
                  </S.InputGroup>

                  <S.NextButton
                    type="button"
                    onClick={() => handleNextStep(FORM_DATA_LAYER.step2)}
                    disabled={
                      handleErrorStepTwo(errors, touched, values) || !dirty
                    }
                  >
                    Avançar
                  </S.NextButton>
                </>
              )}

              {step === 2 && (
                <>
                  <S.InputGroup
                    aria-roledescription="group"
                    style={{
                      gridColumn:
                        values.ProfileType === 'realEstateAgency'
                          ? '1'
                          : ' 1 / 3',
                      transition: '0.5s',
                    }}
                  >
                    <S.PrevArrowWrapper>
                      <S.PrevArrowButton type="button" onClick={handlePrevStep}>
                        <IoArrowBack />
                      </S.PrevArrowButton>
                      <S.Label htmlFor="Email">E-mail</S.Label>
                    </S.PrevArrowWrapper>
                    <S.Input
                      name="Email"
                      id="Email"
                      placeholder="exemplo@gmail.com"
                      haserrors={
                        (errors.Email && touched.Email) || showEmailErrorModal
                      }
                      onChange={e => handleEmailChange(e, setFieldValue)}
                    />
                    {showEmailErrorModal ? (
                      <S.ShowEmailErrorInfoBox>
                        <MdInfoOutline size="16" color=" #ff1f1f" />
                        <p>E-mail já cadastrado.</p>
                        <S.Enter href={BROKERS_LOGIN_URL} target="_blank">
                          Entre
                        </S.Enter>
                        <p>para continuar.</p>
                      </S.ShowEmailErrorInfoBox>
                    ) : null}

                    {errors.Email && touched.Email && (
                      <ErrorMessage infoError={errors.Email} />
                    )}
                  </S.InputGroup>

                  <S.InputGroup
                    aria-roledescription="group"
                    style={{
                      gridColumn: ' 1 / 3',
                    }}
                  >
                    <S.Label mb htmlFor="Password">
                      Senha
                    </S.Label>
                    <S.Input
                      name="Password"
                      id="Password"
                      type={isVisible ? 'text' : 'password'}
                      placeholder="Crie uma senha segura"
                      haserrors={errors.Password && touched.Password}
                    />
                    <S.EyeIcon
                      onClick={() => toggleVisible(!isVisible)}
                      type="button"
                    >
                      {isVisible ? (
                        <Eye width={eyeIconSize} height={eyeIconSize} />
                      ) : (
                        <EyeCrossed width={eyeIconSize} height={eyeIconSize} />
                      )}
                    </S.EyeIcon>

                    {errors.Password && touched.Password && (
                      <ErrorMessage infoError={errors.Password} />
                    )}
                  </S.InputGroup>

                  <S.CheckboxContainer>
                    <S.CustomCheckbox
                      type="checkbox"
                      value={hasAccepted}
                      onChange={() => toggleAccepted(!hasAccepted)}
                    />
                    <S.FakeCheckbox hasAccepted={hasAccepted}>
                      {hasAccepted && (
                        <S.Check>
                          <CheckNew color="#FFF" width={8} height={6} />
                        </S.Check>
                      )}
                    </S.FakeCheckbox>
                    <S.CheckboxLabel>
                      Ao finalizar o cadastro, concordo com os{' '}
                      <S.Link
                        href="https://eemovel.com.br/brokers/termos-de-uso"
                        target="_blank"
                        rel="noopener noreffer"
                      >
                        termos de uso{' '}
                      </S.Link>
                      e as{' '}
                      <S.Link
                        href="https://eemovel.com.br/politica-de-privacidade"
                        target="_blank"
                        rel="noopener noreffer"
                      >
                        políticas de privacidade.
                      </S.Link>{' '}
                      da plataforma.
                    </S.CheckboxLabel>
                  </S.CheckboxContainer>

                  <S.NextButton
                    type="submit"
                    disabled={
                      !hasAccepted ||
                      Object.values(errors).length ||
                      isSubmitting
                    }
                  >
                    Criar conta e começar
                  </S.NextButton>
                </>
              )}
            </S.FormGroupContent>
          </S.FormGroup>
        )}
      </Formik>
    </S.Wrapper>
  )
}

export default BrokersRegister
