export const REGISTER_EMAIL = 'registerEmail'

export const LEAD_EVENT_NAME = 'brokers-form-submit'

export const FORM_DATA_LAYER = {
  step1: 'brokers-register-form-step-1',
  step2: 'brokers-register-form-step-2',
  step3: 'brokers-register-form-step-3',
  closed: 'brokers-register-form-step-closed',
  error: 'brokers-register-form-error',
}
