import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  margin-top: 40%;
  @media only screen and (min-width: 768px) {
    margin-top: 0px;
    max-width: 60%;
  }
  height: 100%;
`

export const Text = styled.h4`
  color: #383939;
  text-align: center;
  font-size: 21px;
  font-family: 'Open Sans', sans-serif;
  line-height: 150%;
  font-weight: 400;
`

export const Logo = styled.img`
  max-width: 15rem;
  max-height: 15rem;
`

export const ReturnButton = styled.button`
  min-width: 20rem;
  background-color: #3e63ad;
  border: none;
  border-radius: 4px;
  padding: 8px;

  color: #fff;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  cursor: pointer;
`
