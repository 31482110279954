import axios from 'axios'
import Cookies from 'js-cookie'

import { showToast } from '@services/toast'

const API_URL = process.env.REACT_APP_API_URL_BROKERS
const LEAD_URL = process.env.REACT_APP_API_URL
const RD_STATION_KEY = process.env.REACT_APP_RD_STATION_KEY
const CITY_URL = `${API_URL}/localizacao/buscacidade`
const REAL_STATE_URL = `${API_URL}/imobiliaria/imobiliariasporcidade`
const REGISTER_URL = `${API_URL}/Account/Register`
const REGISTER_LEAD = `${LEAD_URL}/business-form/public/lead`
const REGISTER_REVOCATION = `${LEAD_URL}/business-form/public/revocation`
const SEND_MESSAGE_URL = `${API_URL}/contato/faleconosco`

export const register = async payload => {
  let success = false

  try {
    let data = {
      form_info: payload.form_info,
      operation_area: payload.operation_area,
      problems_list: '',
      name: payload.name,
      email: payload.email,
      company: payload.company,
      phone: payload.phone,
      city: payload.city,
      utm: payload.utm,
    }

    const response = await axios.post(REGISTER_LEAD, data)

    if (response.status === 200) {
      success = true
    }
  } catch (err) {
    if (err?.response?.data?.Message === 'E-mail já está sendo usado.') {
      showToast(
        'E-mail já está sendo usado. Entre em contato com a nossa equipe para ter mais informações.',
        'warning',
      )
    } else {
      showToast(
        err?.response?.data?.Message ||
          'Ocorreu um erro ao efetuar seu cadastro',
        'failure',
      )
    }
  }
  return success
}

export const revoke = async payload => {
  let success = false

  try {
    let data = {
      name: payload.fullName,
      cpf: payload.document,
      email: payload.email,
    }

    const response = await axios.post(REGISTER_REVOCATION, data)

    if (response.status === 200) {
      success = true
    }
  } catch (err) {
    console.error('err', err)
    showToast('Ocorreu um erro ao enviar dados', 'failure')
  }
  return success
}

export const registerLeadToRdStation = async payload => {
  try {
    await axios.post(
      `https://api.rd.services/platform/conversions?api_key=${RD_STATION_KEY}`,
      {
        event_type: 'CONVERSION',
        event_family: 'CDP',
        payload: {
          conversion_identifier: payload.conversion_identifier,
          name: payload.name,
          email: payload.email,
          company_name: payload.company
            ? payload.company
            : `${payload.name} (${payload.email})`,
          personal_phone: payload.phone,
          city: payload.city,
          cf_qual_problema_voce_quer_resolver: payload.problems_list,
          cf_origem: payload.cf_origem,
          cf_midia: payload.cf_midia,
          cf_campanha: payload.cf_campanha,
          cf_termo: payload.cf_termo,
          cf_conteudo: payload.cf_conteudo,
          traffic_source: payload.cf_origem,
          traffic_campaign: payload.cf_campanha,
          traffic_medium: payload.cf_midia,
        },
      },
    )
  } catch (err) {
    console.error(err)
  }
}

export const getCities = cityName =>
  axios.get(`${CITY_URL}/?criterioBusca=${cityName}`).then(({ data }) =>
    data
      ? data.map(sugg => ({
          value: sugg.Id,
          name: sugg.Nome,
        }))
      : [],
  )

export const getRealStates = (cityId, realStateName) =>
  axios
    .get(`${REAL_STATE_URL}/?criterioBusca=${realStateName}&cidadeId=${cityId}`)
    .then(({ data }) =>
      data.map(sugg => ({
        value: sugg.Id,
        name: sugg.Nome,
      })),
    )
    .catch(err => console.error(err))

export const submitRegister = async (payload, discountCode = null) => {
  let data = {
    UserName: payload.email.toLowerCase(),
    Name: payload.name,
    Email: payload.email.toLowerCase().trim(),
    Password: payload.password,
    PasswordConfirmation: payload.confirmPassword,
    PhoneNumber: payload.phone,
    CityId: payload.city,
    ProfileType: 1,
  }

  const leads = getLeads()

  if (payload.Creci) data.Creci = payload.Creci

  if (discountCode) {
    data.CustomParams = {
      DiscountCoupon: discountCode,
      Campaign: 'LightCampaign',
    }
  }

  if (payload.realStateAgency)
    data.RealStateAgencyName = payload.realStateAgency

  if (leads?.source) {
    data.Campaign = leads.campaign
    data.Source = leads.source
    data.Media = leads.media
  }

  try {
    const response = await axios.post(REGISTER_URL, data)

    return response
  } catch (error) {
    return error.response
  }
}

const getLeads = () => {
  const leads = Cookies.getJSON('__utmzz')
  if (leads) {
    let [source, media, content] = leads?.split('|')

    return {
      source: source?.replace('utmcsr=', ''),
      media: media?.replace('utmcmd=', ''),
      campaign: content?.replace('utmccn=', ''),
    }
  } else {
    return {
      source: '',
      media: '',
      campaign: '',
    }
  }
}

export const getStates = async () => {
  try {
    const response = await axios.get(
      'https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome',
    )

    return response.data
  } catch (error) {
    console.error(error)
  }
}

export const getCitiesIBGE = async id => {
  try {
    const response = await axios.get(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${id}/municipios?orderBy=nome`,
    )

    return response.data
  } catch (error) {
    console.error(error)
  }
}

export const sendMessage = async formData => {
  let success = false

  try {
    const response = await axios.post(SEND_MESSAGE_URL, formData)

    if (response.status === 200) {
      success = true
      showToast('E-mail enviado com sucesso!', 'success')
    }
  } catch (err) {
    if (err?.response?.data?.Message === 'E-mail já está sendo usado.') {
      showToast(err?.response?.data?.Message, 'failure')
    } else {
      showToast(
        err?.response?.data?.Message ||
          'Ocorreu um erro ao enviar o e-mail, se persistir entre em contato com nosso suporte.',
        'failure',
      )
    }
    console.error(err)
  }

  return success
}

export const resetPasswordService = async email => {
  const response = await axios.post(
    `${API_URL}/auth/enviarnovasenha?email=${email}`,
  )

  return response
}
