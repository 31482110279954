import agro from './agro.svg'
import bank from './bank.svg'
import building from './building.svg'
import realty from './realty.svg'
import engineer from './engineer.svg'
import justice from './justice.svg'
import store from './store.svg'
import doubt from './doubt.svg'
import blog from './blog.svg'
import work from './work.svg'

export const Icons = {
  agro,
  bank,
  building,
  realty,
  engineer,
  justice,
  store,
  doubt,
  blog,
  work,
}
