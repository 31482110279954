import React from 'react'
import { useScreenWidth } from '@/utils/windowSize'
import OnBoardDesktop from './onBoardDesktop'
import OnBoardMobile from './onBoardMobile'
import './onBoard.css'

export default function HOnboard({ context, texts, configs }) {
  const isDesktop = useScreenWidth() > 1250

  return (
    <section className="home-onboard">
      {isDesktop ? (
        <OnBoardDesktop context={context} texts={texts} configs={configs} />
      ) : (
        <OnBoardMobile context={context} texts={texts} />
      )}
    </section>
  )
}
