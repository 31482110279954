import React from 'react'
import * as S from './style.js'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { BrokersLogo } from '../../../../../../components/Icons/BrokersLogo/index.js'
import { BrokersLogoBlack } from '../../../../../../components/Icons/BrokersLogoBlack/index.js'

const RegisterWrapper = ({ children, isSuccess }) => {
  const history = useHistory()
  const currentYear = new Date().getFullYear()

  const BROKERS_LOGIN_URL = process.env.REACT_APP_PLATFORM_URL_BROKERS

  return (
    <S.Main isSuccess={isSuccess}>
      <S.HeroContent>
        <S.HeroWrapper>
          <S.Hero onClick={() => history.push('/brokers')}>
            {isSuccess ? <BrokersLogoBlack /> : <BrokersLogo />}
          </S.Hero>
          <S.EnterBtnWrapperDesktop>
            <S.EnterQuestion>Já tem uma conta?</S.EnterQuestion>
            <S.EnterBtn href={BROKERS_LOGIN_URL} target="_blank">
              Entrar
            </S.EnterBtn>
          </S.EnterBtnWrapperDesktop>
        </S.HeroWrapper>
        {children}
      </S.HeroContent>

      <S.EnterBtnWrapperMobile>
        <S.EnterQuestion>Já tem uma conta?</S.EnterQuestion>
        <S.EnterBtn href={BROKERS_LOGIN_URL} target="_blank">
          Entrar
        </S.EnterBtn>
      </S.EnterBtnWrapperMobile>
      <S.Footer isSuccess={isSuccess}>
        Copyright © {currentYear} EEmovel Inteligência Imobiliária - Todos os
        direitos reservados.
      </S.Footer>
    </S.Main>
  )
}

export default RegisterWrapper
