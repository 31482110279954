import React, { useContext } from 'react'
import parse from 'html-react-parser'
import { useScreenWidth } from '@/utils/windowSize'

export default function OnBoardMobile({ context, texts }) {
  const position = useContext(context)

  const mobile = useScreenWidth() < 675

  const animationController = () =>
    mobile ? { start: 400, space: 600 } : { start: 600, space: 900 }

  const entranceAnimation = (even, animationStart) => {
    if (animationStart < position) {
      return even ? 'fade-in-left' : 'fade-in-right'
    }
    return ''
  }

  return (
    <div
      data-cy="HomeOnboardMobileDiv"
      className="home-onboard-wrapper responsive"
    >
      {texts.onBoard.map((item, index) => {
        const even = index % 2 === 1
        const animationStart =
          animationController().start + animationController().space * index

        return (
          <div
            key={'HomeOnboardMobileItemDiv' + index}
            data-cy={'HomeOnboardMobileItemDiv' + index}
            className={`${even && 'bg-primary-red'}`}
          >
            <div
              className={`home-onboard-column ${entranceAnimation(
                even,
                animationStart,
              )}`}
            >
              <article className="home-onboard-article">
                <h1
                  data-cy={'HomeOnboardMobileItemTitleH1' + index}
                  className="home-onboard-article-title"
                >
                  {parse(item.title)}
                </h1>
                <div
                  data-cy={'HomeOnboardMobileItemImgDiv' + index}
                  className={`home-onboard-demo-img ${item.image}`}
                ></div>
                <p
                  data-cy={'HomeOnboardMobileItemDescriptionP' + index}
                  className="home-onboard-article-description"
                >
                  {parse(item.description)}
                </p>
                <a
                  data-cy={'HomeOnboardMobileItemLink' + index}
                  href={item.ref ?? '#home-form'}
                  className="schedule-demonstration-btn"
                >
                  {item.callToAction}
                </a>
              </article>
            </div>
          </div>
        )
      })}
    </div>
  )
}
