import React, { useContext, useEffect } from 'react'
import landingTexts from '../../language/landingTexts'
import parse from 'html-react-parser'
import { LandingContext } from '../..'
import Slider from 'react-slick'

import {
  ClientesAtendidosBigNumber,
  ImoveisRuraisBigNumber,
  ImoveisUrbanosBigNumber,
  LaudosTecnicosBigNumber,
} from '../big-numbers'

import './banner.css'

export default function LandingBanner() {
  const scrollPosition = useContext(LandingContext)

  const [startFirstAnimation, setStartFirstAnimation] = React.useState(false)
  const [startSecondAnimation, setStartSecondAnimation] = React.useState(false)
  useEffect(() => {
    if (scrollPosition > 90) {
      setStartFirstAnimation(true)
    }
    if (scrollPosition > 150) {
      setStartSecondAnimation(true)
    }
  }, [scrollPosition, startSecondAnimation])

  const settings = {
    dots: false,
    autoplay: false,
    arrows: false,
    infinite: false,
    draggable: false,
    slidesToShow: 4,
    slidesToScroll: 0,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          arrows: true,
          autoplay: true,
          autoplaySpeed: 3000,
          speed: 500,
          draggable: true,
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          arrows: true,
          autoplay: true,
          autoplaySpeed: 3000,
          infinite: true,
          speed: 500,
          draggable: true,
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: true,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 3000,
          infinite: true,
          speed: 500,
          draggable: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          dots: true,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 3000,
          infinite: true,
          speed: 500,
          draggable: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <div className="landing-banner">
      <div className="landing-banner__wrapper">
        <div className="landing-banner__content">
          <h1 className="landing-banner__title">
            {parse(landingTexts.banner.title)}
          </h1>
          <p className="landing-banner__description">
            {landingTexts.banner.description}
          </p>
        </div>

        <div className="landing-banner__footer">
          <p
            className={`landing-banner__paragraph ${startFirstAnimation &&
              'slide-in-up'}`}
          >
            {landingTexts.banner.info}
          </p>

          <div
            className={`landing-achievments ${startSecondAnimation &&
              'slide-in-up'}`}
          >
            <Slider {...settings}>
              <ImoveisUrbanosBigNumber />
              <ImoveisRuraisBigNumber />
              <ClientesAtendidosBigNumber />
              <LaudosTecnicosBigNumber />
            </Slider>
          </div>
        </div>
      </div>
    </div>
  )
}
