import styled from 'styled-components'
import Colors from '@colors'

export const ErrorMessageBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin-top: 4px;
`

export const ErrorMessage = styled.p`
  color: ${Colors.redFF};
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 12px;
  font-weight: 600;
  line-height: 150%;
`
