import React, { useState, useEffect, createContext } from 'react'
import { useLocation } from 'react-router-dom'

import Chat from '@components/Chat'

import { eemovel_favicon } from '@images/Landing/eemovel_favicon.png'
import { changeFavicon } from '@utils/changeFavicon'
import { changeTitle } from '@utils/changeTitle'
import { useTrackingInfo } from '@/hooks/useTrackingInfo'

import Colors from '@colors'
import landingTexts from './language/landingTexts'
import LandingHeader from './components/header/header'
import LandingBanner from './components/banner/banner'
import HOnboard from '../../components/layout/onBoard/onBoard'
import HFooter, { HFooterOpt } from '../../components/layout/footer/footer'
import LandingSegments from './components/segments/segments'
import LandingBlog from './components/blog/blog'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '../../index.css'
import './index.css'

export const LandingContext = createContext(0)

const Landing = () => {
  const [scrollPosition, setScrollPosition] = useState(0)
  const mediumRes = window.innerWidth < 1200
  const mobileRes = window.innerWidth < 768

  const { getTrackingInfo } = useTrackingInfo()
  const { search } = useLocation()
  const query = new URLSearchParams(search)

  const animationConfigs = {
    animationStart: 700,
  }

  function scrollHandle() {
    const scrollPosition =
      window.pageYOffset || document.documentElement.scrollTop
    setScrollPosition(scrollPosition)
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollHandle, { passive: true })
    changeTitle()
    changeFavicon(eemovel_favicon)

    getTrackingInfo(query)

    return () => {
      window.removeEventListener('scroll', scrollHandle)
    }
  }, [getTrackingInfo, query])

  useEffect(() => {
    const simulationData = localStorage.getItem('simulationData')
    window.history.replaceState(null, '')
    if (simulationData === null) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    } else {
      window.scrollTo({
        top: 750,
        behavior: 'smooth',
      })
    }
    localStorage.removeItem('simulationData')
  }, [])

  useEffect(() => {
    const simulationDataseg = localStorage.getItem('simulationDataseg')
    window.history.replaceState(null, '')
    if (simulationDataseg === null) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    } else {
      if (mediumRes) {
        window.scrollTo({
          top: 2150,
          behavior: 'smooth',
        })
      } else {
        window.scrollTo({
          top: 3250,
          behavior: 'smooth',
        })
      }
    }
    localStorage.removeItem('simulationDataseg')
  }, [mediumRes])

  return (
    <LandingContext.Provider value={scrollPosition}>
      <div id="landing" className="landing-body">
        <LandingHeader context={LandingContext} />
        <LandingBanner />
        <h1 className="landing-onboard-title">Conheça nossas soluções</h1>
        <HOnboard
          context={LandingContext}
          texts={landingTexts}
          configs={animationConfigs}
        ></HOnboard>
        <LandingSegments />
        {!mobileRes && <LandingBlog />}
        <HFooter texts={landingTexts} newsletter={false} dark={false}>
          <HFooterOpt.LandingNav texts={landingTexts} />
        </HFooter>
        <Chat routeName="Eemovel" color={Colors.secondary} />
      </div>
    </LandingContext.Provider>
  )
}

export default Landing
