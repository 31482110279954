import * as prismic from '@prismicio/client'

export const EEMOVEL_REPOSITORY = process.env.REACT_APP_EEMOVEL_REPOSITORY
export const EEMOVEL_TOKEN = process.env.REACT_APP_EEMOVEL_ACCESS_TOKEN

export const BROKERS_REPOSITORY = process.env.REACT_APP_BROKERS_REPOSITORY
export const BROKERS_TOKEN = process.env.REACT_APP_BROKERS_ACCESS_TOKEN

export const eemovelClient = prismic.createClient(EEMOVEL_REPOSITORY, {
  accessToken: EEMOVEL_TOKEN,
})

export const brokersClient = prismic.createClient(BROKERS_REPOSITORY, {
  accessToken: BROKERS_TOKEN,
})
