import React from 'react'

import NewLoader from '../../../../../components/NewLoader'
import { useRegisterPage } from '../../../components/Context/RegisterContext'
import RegisterSuccess from '../../../components/Register/sub-components/RegisterSuccess'
import RegisterWrapper from '../../../components/Register/sub-components/RegisterWrapper'
import BrokersRegister from '@/pages/Brokers/components/Register/sub-components/RegisterForm'

const RegisterPageWrapper = () => {
  const { success, isLoading } = useRegisterPage()

  return (
    <RegisterWrapper isSuccess={success}>
      {isLoading && <NewLoader />}
      {success ? <RegisterSuccess /> : <BrokersRegister />}
    </RegisterWrapper>
  )
}

export default RegisterPageWrapper
