import { logos } from '../assets/svg/logos-index.js'
import { workWithUs } from '../../../constants/footer.js'

const header = {
  solutions: {
    title: 'Soluções',
    list: [
      {
        title: `${logos.brokers} A plataforma pioneira de captação inteligente de imóveis`,
        href: '/brokers',
      },
      {
        title: `${logos.agro} Entenda seu potencial financeiro, alcance novas propriedades agrícolas e acelere suas vendas`,
        href: '/agro',
      },
      {
        title: `${logos.valuation} Otimize tempo e recursos nas avaliações de imóveis urbanos ou rurais`,
        href: '/valuation',
      },
      {
        title: `${logos.developer} Saiba o que construir, para quem construir e onde construir`,
        href: '/developer',
      },
      {
        title: `${logos.location} Entenda o contexto imobiliário da região e encontre o ponto comercial ideal`,
        href: '/location',
      },
    ],
  },
  segments: {
    title: 'Segmentos',
    list: [
      {
        title: 'Agronegócio',
        description:
          'Prospecte clientes e acelere suas vendas com dados estratégicos.',
        href: '/agro',
      },
      {
        title: 'Bancos, Cooperativas e Fintechs',
        description: 'Tenha avaliações de imóveis em tempo recorde.',
        href: '/valuation',
      },
      {
        title: 'Construtoras e Incorporadoras',
        description: 'Construa o que seus clientes querem comprar.',
        href: '/developer',
      },
      {
        title: 'Corretores e Imobiliárias',
        description:
          'Expanda sua carteira de imóveis com nossa plataforma de captação.',
        href: '/brokers',
      },
      {
        title: 'Engenheiro Avaliador',
        description: 'Acelere seu trabalho com nossas bases de dados.',
        href: '/valuation',
      },
      {
        title: 'Jurídico',
        description: 'Obtenha o preço certo de imóveis em disputa.',
        href: '/valuation',
      },
      {
        title: 'Varejo e Franquias',
        description: 'Encontre o ponto ideal para expansão comercial.',
        href: '/location',
      },
      {
        title: 'Precisa de Ajuda?',
        description:
          'Não encontrou o que precisava? Entre em contato com nossos especialistas.',
        href: '/fale-conosco',
      },
    ],
  },
  aboutUs: {
    title: 'Sobre nós',
    list: [
      {
        title: 'Blog',
        description:
          'Conte com nossas soluções para potencializar sua forma de fazer negócios.',
        href: 'https://blog.eemovel.com.br/',
      },
      {
        title: 'Trabalhe Conosco',
        description:
          'Faça parte do nosso time e ajude a transformar o mercado imobiliário.',
        href: 'https://eemovel.vagas.solides.com.br/',
      },
    ],
  },
}

const banner = {
  title:
    'Transformamos o jeito de fazer <span className="highlight">negócios</span> por meio de <span className="highlight">tecnologia</span> e <span className="highlight">informação</span> de <span className="highlight">ativos imobiliários</span>',
  description:
    'Conte conosco para impulsionar sua estratégia de crescimento, auxiliar na tomada de decisões e gerar insights que potencializarão seu negócio.',
  info: 'Somos líderes em informação e temos',
}

const onBoard = [
  {
    title: `${logos.brokers} A plataforma <span className="highlight-brokers">pioneira</span> de <span className="highlight-brokers">captação inteligente</span> de imóveis`,
    description:
      '<strong>Conectamos o corretor aos proprietários dos melhores imóveis para gerar bons negócios.</strong> Encontre imóveis, crie alertas, faça avaliações e faça a gestão do processo, tudo em um só lugar e em qualquer dispositivo.',
    image: 'landing-brokers',
    ref: '/brokers',
    callToAction: 'Saiba mais',
  },
  {
    title: `${logos.agro} Entenda seu <span className="highlight-agro">potencial financeiro</span>, alcance novas propriedades agrícolas e <span className="highlight-agro">acelere suas vendas</span>`,
    description:
      '<strong>Tenha exclusividade a informações estratégicas.</strong> Encontre propriedades rurais, entenda o potencial de uma região, elabore sua estratégia comercial e faça novos negócios.',
    image: 'landing-agro',
    ref: '/agro',
    callToAction: 'Saiba mais',
  },
  {
    title: `${logos.valuation} Otimize tempo e recursos nas <span className="highlight-valuation">avaliações de imóveis</span> urbanos ou rurais`,
    description:
      '<strong>Avaliação de imóveis sem a necessidade de vistoria.</strong> Conte com nosso time de especialistas e soluções criadas especialmente para trazer agilidade aos processos de avaliação imobiliária.',
    image: 'landing-valuation',
    ref: '/valuation',
    callToAction: 'Saiba mais',
  },
  {
    title: `${logos.developer} Saiba <span className="highlight-developer">o que</span> construir, <span className="highlight-developer">para quem</span> construir e <span className="highlight-developer">onde</span> construir`,
    description:
      '<strong>Valide seu empreendimento antes de começar a construir.</strong> Conte com a qualidade e análise dos principais dados do mercado imobiliário. Reduza os riscos e tenha mais segurança na tomada de decisão.',
    image: 'landing-developer',
    ref: '/developer',
    callToAction: 'Saiba mais',
  },
  {
    title: `${logos.location} Entenda o <span className="highlight-location">contexto imobiliário</span> da região e encontre o <span className="highlight-location">ponto comercial ideal</span>`,
    description:
      '<strong>Encontre o imóvel ideal para o crescimento do seu negócio.</strong> Conte com inteligência de prospecção de imóveis não ofertados, ou ainda, crie alertas de monitoramento 24 horas por dia, contando com a nossa base de imóveis ofertados',
    image: 'landing-location',
    ref: '/location',
    callToAction: 'Saiba mais',
  },
]

const segments = [
  {
    icon: 'agro',
    title: 'Agronegócio',
    description:
      'Prospecte clientes e acelere suas vendas com dados estratégicos.',
    href: '/agro',
  },
  {
    icon: 'bank',
    title: 'Bancos, Cooperativas e Fintechs',
    description: 'Tenha avaliações de imóveis em tempo recorde.',
    href: '/valuation',
  },
  {
    icon: 'building',
    title: 'Construtoras e Incorporadoras',
    description: 'Construa o que seus clientes querem comprar.',
    href: '/developer',
  },
  {
    icon: 'realty',
    title: 'Corretores e Imobiliárias',
    description:
      'Expanda sua carteira de imóveis com nossa plataforma de captação.',
    href: '/brokers',
  },
  {
    icon: 'engineer',
    title: 'Engenheiro Avaliador',
    description: 'Acelere seu trabalho com nossas bases de dados.',
    href: '/valuation',
  },
  {
    icon: 'justice',
    title: 'Jurídico',
    description: 'Obtenha o preço certo de imóveis em disputa.',
    href: '/valuation',
  },
  {
    icon: 'store',
    title: 'Varejo e Franquias',
    description: 'Encontre o ponto ideal para expansão comercial.',
    href: '/location',
  },
  {
    icon: 'doubt',
    title: 'Precisa de Ajuda?',
    description:
      'Não encontrou o que precisava? Entre em contato com nossos especialistas.',
    href: '/fale-conosco',
  },
]

const strip = {
  title:
    'Há mais de 5 anos impactando negócios em todo o Brasil. <br> Conheça alguns de nossos clientes:',
  paragraphs: [
    'Mais de 7 milhões de anúncios comerciais',
    'Mais de 86 milhões de imóveis negociados',
    'Mais de 17 milhões de imóveis disponível',
    '970 cidades disponíveis',
  ],
}

const about = {
  title: 'Conheça mais sobre a gente',
}

const form = {
  title: 'Entre em contato com nosso time de especialistas',
  inputs: [
    {
      label: 'Nome',
      name: 'name',
      type: 'text',
      placeholder: 'Digite seu nome',
      pattern: undefined,
      minLength: undefined,
      maxLength: undefined,
    },
    {
      label: 'Telefone',
      name: 'phone',
      type: 'tel',
      placeholder: '00 0000 00000',
      pattern: undefined,
      minLength: 10,
      maxLength: 15,
    },
    {
      label: 'Email',
      name: 'email',
      type: 'email',
      placeholder: 'Digite seu email',
      pattern: undefined,
      minLength: undefined,
      maxLength: undefined,
    },
  ],
}

const footer = {
  navLinks: [
    {
      title: 'Suporte',
      link: 'https://ajuda.eemovel.com.br/brokers',
    },
    {
      title: 'Blog',
      link: 'https://blog.eemovel.com.br/',
    },
    {
      title: 'Política de Privacidade',
      link: '/politica-de-privacidade',
    },
    {
      title: 'Política de Cookies',
      link: '/politica-de-cookies',
    },
    {
      title: 'Termos de Uso',
      link: '/termos-de-uso',
    },
    {
      title: workWithUs.title,
      link: workWithUs.link,
    },
    {
      title: 'Revogar dados',
      link: '/revogar-dados',
    },
  ],
  navProducts: [
    {
      title: 'Agro',
      link: '/agro',
    },
    {
      title: 'Brokers',
      link: '/brokers',
    },
    {
      title: 'Developer',
      link: '/developer',
    },
    {
      title: 'Location',
      link: '/location',
    },
    {
      title: 'Valuation',
      link: '/valuation',
    },
  ],
  address:
    'Rua Duque de Caxias, 209, Centro <br/><br/> Cascavel - PR, 85801-100',
  subscribe:
    'Inscreva-se no <span className="highlight">nosso newsletter</span>',
  copyright:
    'Copyright © 2022 EEmovel Inteligência Imobiliária - Todos os direitos reservados - CNPJ: 21.519.937/0001-48.',
  contact: {
    title: 'Contato',
    list: ['Fone: (45) 99915-0249', 'contato@eemovel.com.br'],
  },
}

const landingTexts = {
  header,
  banner,
  onBoard,
  segments,
  strip,
  about,
  form,
  footer,
}

export default landingTexts
