import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'

import parse from 'html-react-parser'
import EEmovelDarkLogo from '../../../Location/assets/svg/EEmovelDarkLogo'
import landingTexts from '../../language/landingTexts'
import { BiChevronDown } from 'react-icons/bi'
import { GiHamburgerMenu } from 'react-icons/gi'
import { GrClose } from 'react-icons/gr'
import { Icons } from '../../assets/svg/icons-index'
import Arrow from '../../assets/svg/arrow.svg'

import './header.css'

const segmentIcons = [
  Icons.agro,
  Icons.bank,
  Icons.building,
  Icons.realty,
  Icons.engineer,
  Icons.justice,
  Icons.store,
  Icons.doubt,
]

const aboutUsIcons = [Icons.blog, Icons.work]

const handleToggleDropdown = (isToggled, handler, ref) => {
  const toggle = () => handler(prevstate => !prevstate)

  if (isToggled) {
    setTimeout(() => {
      toggle()
    }, 500)
    ref.current?.classList.add('close-animation')
  } else {
    toggle()
  }
}

export default function LandingHeader() {
  const isMobile = window.innerWidth < 768

  const [toggleSideBar, setToggleSideBar] = useState(false)

  return (
    <header className={`landing-header`}>
      <div className={`landing-header__wrapper`}>
        <EEmovelDarkLogo dark={false} product={'landing'} />
        {isMobile ? (
          <>
            <button
              className="landing-header__menu-hamburguer"
              onClick={() => setToggleSideBar(prevState => !prevState)}
            >
              {toggleSideBar ? <GrClose /> : <GiHamburgerMenu />}
            </button>

            {toggleSideBar && (
              <div className="landing-header__sidebar">
                <nav className="landing-nav">
                  <LandingHeader.Solutions />
                  <LandingHeader.Segments />
                  <LandingHeader.AboutUs />
                </nav>
                <Link
                  to="/fale-conosco"
                  className="landing-header-support__btn"
                >
                  Fale conosco
                  <img
                    width="15px"
                    heigth="15px"
                    className="landing-header__dropdown-item-arrow-icon"
                    src={Arrow}
                    alt="arrow-icon"
                  />
                </Link>
              </div>
            )}
          </>
        ) : (
          <>
            <nav className="landing-nav">
              <LandingHeader.Solutions />
              <LandingHeader.Segments />
              <LandingHeader.AboutUs />
            </nav>
            <Link to="/fale-conosco" className="landing-header-support__btn">
              Fale conosco
            </Link>
          </>
        )}
      </div>
    </header>
  )
}

LandingHeader.Solutions = function LandingHeaderSolutions() {
  const [toggleDropdown, setToggleDropdown] = useState(false)
  const dropDownRef = React.useRef(null)
  return (
    <div className="landing-nav__item">
      <button
        className={`landing-nav__item-button ${toggleDropdown && 'active'}`}
        onClick={() =>
          handleToggleDropdown(toggleDropdown, setToggleDropdown, dropDownRef)
        }
      >
        <span>{landingTexts.header.solutions.title}</span>
        <BiChevronDown />
      </button>
      {toggleDropdown && (
        <div ref={dropDownRef} className="landing-header__dropdown">
          <div className="landing-header__dropdown-wrapper">
            {landingTexts.header.solutions.list.map((item, index) => (
              <Link
                key={`${index}${item.title}`}
                aria-label={item.title}
                to={item.href}
                className="landing-header__dropdown-item-description"
              >
                {parse(item.title)}
                <img
                  width="20"
                  heigth="20"
                  className="landing-header__dropdown-item-arrow-icon"
                  src={Arrow}
                  alt="arrow-icon"
                />
              </Link>
            ))}
          </div>
          <div
            className="landing-header__dropdown-backdrop"
            role="button"
            onClick={() =>
              handleToggleDropdown(
                toggleDropdown,
                setToggleDropdown,
                dropDownRef,
              )
            }
          ></div>
        </div>
      )}
    </div>
  )
}

LandingHeader.Segments = function LandingHeaderSegments() {
  const [toggleDropdown, setToggleDropdown] = useState(false)
  const dropDownRef = React.useRef(null)

  return (
    <div className="landing-nav__item">
      <button
        className={`landing-nav__item-button ${toggleDropdown && 'active'}`}
        onClick={() =>
          handleToggleDropdown(toggleDropdown, setToggleDropdown, dropDownRef)
        }
      >
        <span>{landingTexts.header.segments.title}</span>
        <BiChevronDown />
      </button>
      {toggleDropdown && (
        <div ref={dropDownRef} className="landing-header__dropdown">
          <div className="landing-header__dropdown-wrapper">
            {landingTexts.header.segments.list.map((item, index) => (
              <Link to={item.href} key={`${index}${item.title}`}>
                <img
                  width="15px"
                  heigth="15px"
                  className="landing-header__dropdown-item-icon"
                  src={segmentIcons[index]}
                  alt={item.title}
                />
                <div>
                  <h1 className="landing-header__dropdown-item-title">
                    {item.title}
                  </h1>
                  <span className="landing-header__dropdown-item-description">
                    {item.description}
                  </span>
                </div>
                <img
                  width="20"
                  heigth="20"
                  className="landing-header__dropdown-item-arrow-icon"
                  src={Arrow}
                  alt="arrow-icon"
                />
              </Link>
            ))}
          </div>
          <div
            className="landing-header__dropdown-backdrop"
            role="button"
            onClick={() =>
              handleToggleDropdown(
                toggleDropdown,
                setToggleDropdown,
                dropDownRef,
              )
            }
          ></div>
        </div>
      )}
    </div>
  )
}

LandingHeader.AboutUs = function LandingHeaderAboutUs() {
  const [toggleDropdown, setToggleDropdown] = useState(false)
  const dropDownRef = React.useRef(null)

  return (
    <div className="landing-nav__item">
      <button
        className={`landing-nav__item-button ${toggleDropdown && 'active'}`}
        onClick={() =>
          handleToggleDropdown(toggleDropdown, setToggleDropdown, dropDownRef)
        }
      >
        <span>{landingTexts.header.aboutUs.title}</span>
        <BiChevronDown />
      </button>
      {toggleDropdown && (
        <div ref={dropDownRef} className="landing-header__dropdown">
          <div className="landing-header__dropdown-wrapper grid-column">
            {landingTexts.header.aboutUs.list.map((item, index) => (
              <a
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
                key={`${index}${item.title}`}
              >
                <img
                  width="15px"
                  heigth="15px"
                  className="landing-header__dropdown-item-icon"
                  src={aboutUsIcons[index]}
                  alt={item.title}
                />
                <div>
                  <h1 className="landing-header__dropdown-item-title">
                    {item.title}
                  </h1>
                  <span className="landing-header__dropdown-item-description">
                    {item.description}
                  </span>
                </div>
                <img
                  width="15px"
                  heigth="15px"
                  className="landing-header__dropdown-item-arrow-icon"
                  src={Arrow}
                  alt="arrow-icon"
                />
              </a>
            ))}
          </div>
          <div
            className="landing-header__dropdown-backdrop"
            role="button"
            onClick={() =>
              handleToggleDropdown(
                toggleDropdown,
                setToggleDropdown,
                dropDownRef,
              )
            }
          ></div>
        </div>
      )}
    </div>
  )
}
