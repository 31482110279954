import * as S from './styles'
import React, { useEffect } from 'react'
import Logo from '../../../../../../assets/svg/Logo.svg'
import ArrowLeft from '../../../../../../assets/svg/arrow_back.svg'
import { useHistory } from 'react-router-dom'

const RegisterSuccess = () => {
  const history = useHistory()

  useEffect(() => {
    document.body.style.overflow = 'hidden'
  }, [])

  return (
    <S.Container>
      <S.Logo src={Logo} alt="eemovel-pin-blue" />
      <S.Text>
        Tudo pronto! Logo nossos especialistas entrarão em contato{' '}
        <strong>via whatsapp </strong>
        para liberar seu acesso.
      </S.Text>
      <S.ReturnButton onClick={() => history.push('/')}>
        <img alt="Seta branca apontando para a esquerda" src={ArrowLeft} />
        Retornar à página inicial
      </S.ReturnButton>
    </S.Container>
  )
}

export default RegisterSuccess
