import React, { useContext } from 'react'
import parse from 'html-react-parser'
import AnimationsFactory from './animationsFactory'

export default function OnBoardDesktop({ context, texts, configs }) {
  const position = useContext(context)

  const animations = new AnimationsFactory(configs?.animationStart)

  const animation = {
    parallaxStart: configs?.animationStart || 700,
    parallaxSpeed: 800,
  }

  return (
    <div data-cy="HomeOnboardDesktopDiv" className="home-onboard-wrapper">
      {texts.onBoard.map((item, index) => {
        const animationStart =
          animation.parallaxStart + animation.parallaxSpeed * index
        const even = index % 2 === 1

        return (
          <div
            key={'HomeOnboardDesktopItemDiv' + index}
            data-cy={'HomeOnboardDesktopItemDiv' + index}
            className={`home-onboard-row ${even && 'reverse bg-primary-red'}`}
          >
            <article
              data-cy={'HomeOnboardDesktopItemArticle' + index}
              className="home-onboard-article"
              style={{
                left: `${
                  animations.parallax(animationStart, position)[even ? 0 : 1]
                }px`,
                opacity: `${animations.fadeInOut(animationStart, position)}`,
              }}
            >
              <h1
                data-cy={'HomeOnboardDesktopItemTitleH1' + index}
                className="home-onboard-article-title"
              >
                {parse(item.title)}
              </h1>
              <p
                data-cy={'HomeOnboardDesktopItemDescriptionP' + index}
                className="home-onboard-article-description"
              >
                {parse(item.description)}
              </p>
              <a
                data-cy={'HomeOnboardDesktopItemLink' + index}
                href={item.ref ?? '#home-form'}
                className="schedule-demonstration-btn"
              >
                {item.callToAction}
              </a>
            </article>
            <div
              data-cy={'HomeOnboardDesktopItemImageDiv' + index}
              className={`home-onboard-demo-img ${item.image} ${even &&
                'reverse'}`}
              style={{
                left: `${animations.parallax(animationStart, position)[
                  even ? 1 : 0
                ] + (even ? -110 : 110)}px`,
                opacity: `${animations.fadeInOut(animationStart, position)}`,
              }}
            ></div>
          </div>
        )
      })}
    </div>
  )
}
