import React from 'react'
import Colors from '@colors'

const CheckNew = ({ width = 18, height = 14, color = Colors.green9B }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 14"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5939 0.0820312H16.0921C15.8815 0.0820312 15.6817 0.178711 15.5528 0.344141L6.69483 11.5654L2.44737 6.18359C2.38312 6.10202 2.30122 6.03605 2.20782 5.99066C2.11442 5.94526 2.01196 5.92161 1.90811 5.92149H0.406353C0.262408 5.92149 0.182916 6.08692 0.271002 6.19863L6.15557 13.6537C6.43057 14.0018 6.95909 14.0018 7.23624 13.6537L17.7292 0.357031C17.8173 0.247461 17.7378 0.0820312 17.5939 0.0820312V0.0820312Z"
      fill="inherit"
    />
  </svg>
)

export default CheckNew
