import React from 'react'

const facebookIcon = () => (
  <svg
    width="34"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="facebook-icon"
  >
    <path d="M27.9375 0.9375H2.0625C1.44023 0.9375 0.9375 1.44023 0.9375 2.0625V27.9375C0.9375 28.5598 1.44023 29.0625 2.0625 29.0625H27.9375C28.5598 29.0625 29.0625 28.5598 29.0625 27.9375V2.0625C29.0625 1.44023 28.5598 0.9375 27.9375 0.9375ZM24.6891 9.14648H22.4426C20.6812 9.14648 20.3402 9.9832 20.3402 11.2137V13.9242H24.5449L23.9965 18.1676H20.3402V29.0625H15.9563V18.1711H12.2895V13.9242H15.9563V10.7953C15.9563 7.16367 18.1746 5.18438 21.416 5.18438C22.9699 5.18438 24.3023 5.30039 24.6926 5.35313V9.14648H24.6891Z" />
  </svg>
)

export default facebookIcon
