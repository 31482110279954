import React from 'react'

const linkedinIcon = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="linkedin-icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.5 3.257C0.5 2.5258 0.790469 1.82455 1.30751 1.30751C1.82454 0.790472 2.5258 0.500004 3.257 0.500004H30.74C31.1024 0.499412 31.4613 0.570298 31.7962 0.708604C32.1311 0.846909 32.4355 1.04992 32.6919 1.30601C32.9482 1.5621 33.1516 1.86624 33.2902 2.20102C33.4289 2.5358 33.5002 2.89464 33.5 3.257V30.74C33.5004 31.1024 33.4293 31.4614 33.2909 31.7963C33.1524 32.1313 32.9492 32.4356 32.693 32.692C32.4368 32.9483 32.1326 33.1516 31.7977 33.2903C31.4628 33.4289 31.1039 33.5002 30.7415 33.5H3.257C2.89482 33.5 2.53619 33.4286 2.2016 33.29C1.867 33.1513 1.56301 32.9481 1.30698 32.692C1.05095 32.4358 0.847898 32.1317 0.709434 31.797C0.57097 31.4624 0.499803 31.1037 0.5 30.7415V3.257ZM13.562 13.082H18.0305V15.326C18.6755 14.036 20.3255 12.875 22.805 12.875C27.5585 12.875 28.685 15.4445 28.685 20.159V28.892H23.8745V21.233C23.8745 18.548 23.2295 17.033 21.5915 17.033C19.319 17.033 18.374 18.6665 18.374 21.233V28.892H13.562V13.082ZM5.312 28.6865H10.124V12.875H5.312V28.685V28.6865ZM10.8125 7.718C10.8216 8.13001 10.7483 8.53968 10.5969 8.92298C10.4455 9.30627 10.219 9.65546 9.93085 9.95005C9.64267 10.2446 9.29855 10.4787 8.91868 10.6385C8.53882 10.7983 8.13086 10.8806 7.71875 10.8806C7.30664 10.8806 6.89868 10.7983 6.51882 10.6385C6.13895 10.4787 5.79483 10.2446 5.50665 9.95005C5.21847 9.65546 4.99204 9.30627 4.84064 8.92298C4.68924 8.53968 4.61593 8.13001 4.625 7.718C4.64281 6.90929 4.97658 6.13969 5.55483 5.57403C6.13308 5.00838 6.90984 4.69163 7.71875 4.69163C8.52766 4.69163 9.30442 5.00838 9.88267 5.57403C10.4609 6.13969 10.7947 6.90929 10.8125 7.718Z"
    />
  </svg>
)

export default linkedinIcon
