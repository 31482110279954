import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  background: #f3f5f9;
  width: 100%;
  height: 100%;

  @media screen and (max-width: 768px) {
    padding: 0;
  }

  @media screen and (max-width: 1024px) {
    padding: 12px;
  }
`
export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    margin: 40px 0 47px 0;
  }

  h1 {
    font-family: 'Open Sans', sans-serif;
    font-size: 28px;
    font-weight: bold;
    color: #3e63ad;
  }

  p {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #383939;
  }
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  line-height: 28px;
  color: #383939;
  padding: 0 200px;

  strong {
    font-size: 1rem;
    font-weight: bold;
    color: #3e63ad;
  }

  @media screen and (max-width: 768px) {
    padding: 0 8px;
  }

  @media screen and (max-width: 1024px) {
    padding: 0 40px;
  }
`
