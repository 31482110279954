import React from 'react'

const youtubeIcon = () => (
  <svg
    width="36"
    height="26"
    viewBox="0 0 36 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="youtube-icon"
  >
    <path d="M35.25 4.76061C35.0462 4.03346 34.6491 3.37513 34.101 2.85561C33.5374 2.32012 32.8467 1.93707 32.094 1.74261C29.277 1.00011 17.991 1.00011 17.991 1.00011C13.286 0.946583 8.58214 1.18203 3.906 1.70511C3.15328 1.91393 2.46384 2.30554 1.89899 2.84511C1.34399 3.37911 0.941995 4.03761 0.731995 4.75911C0.227534 7.47673 -0.017567 10.2361 -5.01133e-06 13.0001C-0.018005 15.7616 0.226495 18.5201 0.731995 21.2411C0.937495 21.9596 1.33799 22.6151 1.8945 23.1446C2.45099 23.6741 3.144 24.0566 3.906 24.2591C6.7605 25.0001 17.991 25.0001 17.991 25.0001C22.702 25.0537 27.4118 24.8183 32.094 24.2951C32.8467 24.1007 33.5374 23.7176 34.101 23.1821C34.656 22.6526 35.0505 21.9941 35.2485 21.2771C35.7661 18.5605 36.0178 15.8 36 13.0346C36.0389 10.2575 35.7876 7.48396 35.25 4.75911V4.76061ZM14.403 18.1361V7.86561L23.793 13.0016L14.403 18.1361Z" />
  </svg>
)

export default youtubeIcon
