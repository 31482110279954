import React, { useEffect, useState } from 'react'
import { FiChevronRight } from 'react-icons/fi'
import parse from 'html-react-parser'

import { getLastNews } from '@services/news'
import './blog.css'

export default function LandingBlog() {
  const [loading, setLoading] = useState(true)
  const [news, setNews] = useState([])

  useEffect(() => {
    async function getNews() {
      try {
        const response = await getLastNews()
        setNews(response)
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    }
    getNews()

    return () => {
      setNews([])
    }
  }, [loading])

  return (
    <section className="landing-blog__section">
      <h1 className="landing-blog__title">Acompanhe nosso blog</h1>
      <div className="landing-blog-news__wrapper">
        {loading
          ? Array(4)
              .fill(0)
              .map((_, index) => (
                <div
                  className="landing-blog-news__card__skeleton"
                  key={index}
                ></div>
              ))
          : news?.map(({ index, title, link, imageUrl, subtitle }) => (
              <button
                className="landing-blog-news__card"
                type="button"
                key={index + title}
                onClick={() => window.open(link, '_blank')}
              >
                <img
                  className="landing-blog-news__image"
                  alt={title}
                  src={imageUrl}
                />
                <h1 className="landing-blog-news__title">{title}</h1>
                <h2 className="landing-blog__description">
                  {parse(subtitle.replace(/(<([^>]+)>)/gi, ''))}
                </h2>

                <p className="landing-blog-news__link">
                  Ver mais <FiChevronRight />
                </p>
              </button>
            ))}
      </div>
    </section>
  )
}
