import axios from 'axios'

import { showToast } from '@services/toast'

import translateCapabilities from '@utils/translateCapabilities'

const ENVS = {
  'page-qa': 'qa',
  'page-dev': 'development',
  eemovel: 'production',
  'localhost:3000': 'development',
}

const DOMAIN = process.env.REACT_APP_DOMAIN
const ENVIROMENT = ENVS[window.location.host.split('.')[0]]
const API_URL = process.env.REACT_APP_API_URL_BROKERS
const GET_TOKEN = `${API_URL}/Token`
const GET_USER_INFO = `${API_URL}/Account/UserInfo`
const FORGOT_PASSWORD_URL = `${API_URL}/auth/enviarnovasenha?email=`

const tokenName = `eemovel_access_token_${ENVIROMENT}`
const credentialName = `eemovel_credential_${ENVIROMENT}`
const cookieConfiguration = { expires: 3, domain: DOMAIN, path: '/' }

const LoginErrors = {
  invalid_grant: 'Usuário ou senha incorretos',
  manager_subscription_expired: 'A inscrição do seu gestor expirou',
  user_deleted: 'Esse usuário não pode fazer login',
}

export const login = async ({ email, password }, setError, to = null) => {
  const params = new URLSearchParams()
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  }
  let success = false

  params.append('username', email)
  params.append('password', password)
  params.append('grant_type', 'password')

  try {
    const response = await axios.post(GET_TOKEN, params, config)

    if (response?.status === 200 && response?.data) {
      if (response.data?.is_migrated) {
        const token = response.data.access_token
        const userInfo = await getUserInfo(token)

        setToken(token)

        if (userInfo) {
          success = true
          setUserInfo(userInfo)

          if (to) window.location = `${process.env.REACT_APP_APP_URL}${to}`
          else window.location = process.env.REACT_APP_APP_URL
        } else {
          setError('Ocorreu um erro em nosso servidor, pedimos desculpas')
        }
      } else {
        window.location = `${process.env.REACT_APP_LEGACY_APP_URL}partner/${response.data.legacy_token}`
      }
    }
  } catch (err) {
    console.error(err)
    if (err?.response?.status === 400)
      setError(
        LoginErrors[err.response?.data?.error] ||
          'Ocorreu um erro em nosso servidor, pedimos desculpas',
      )
    else if (
      err?.response?.status === 401 &&
      err.response?.data?.error === 'user_not_activated'
    )
      setError(
        'Esse usuário ainda não foi ativado. Aguarde o contato de nossa equipe.',
      )
    else if (
      err?.response?.status === 401 &&
      err.response?.data?.error ===
        'user_inactivated_by_manager_please_contact_him_for_liberation'
    )
      setError(
        'Usuário inativado pelo gestor. Entre em contato com o gerente da sua imobiliária para pedir liberação.',
      )
    else setError('Ocorreu um erro em nosso servidor, pedimos desculpas')
  }

  return success
}

export const setToken = token => {
  localStorage.setItem(tokenName, token)
}

export const getToken = () => {
  const token = localStorage.getItem(tokenName)

  return token
}

const getUserInfo = async (token = getToken()) => {
  const headers = { Authorization: `Bearer ${token}` }
  let result = null

  try {
    const response = await axios.get(GET_USER_INFO, { headers })

    if (response.status === 200 && response?.data) {
      const info = response.data
      let citiesNames = info.Cities.map(city => city.CityName)
      let citiesIds = info.Cities.map(city => city.CityId)

      if (citiesIds.length === 0 && citiesNames.length === 0) {
        citiesNames.push(info.City.split(' - ')[0])
        citiesIds.push(info.CityId)
      }

      result = {
        userId: info.Id,
        profileType: info.ProfileType,
        username: info.Name.trim(),
        realStateId: info.RealStateAgencyId,
        managerId: info.ManagerId,
        ufId: info.StateId,
        email: info.Email,
        cityId: info.CityId,
        expiresIn: new Date(info.SubscriptionExpiresIn)?.toLocaleDateString(
          'pt-BR',
        ),
        city: info.City,
        telephone: info.PhoneNumber,
        roles: info.Roles,
        menus: info?.Menus?.map(menu => menu.Name) || [],
        modules: info.Modulues,
        capabilities: info.CapabilitiesId
          ? translateCapabilities(info.CapabilitiesId)
          : [],
        plan: info?.PlanName?.toLowerCase(),
        maxAccounts: info.MaxAccounts,
        maxCities: info.MaxCities,
        firstLogin: info.FirstLogin,
        expired: info.Expired,
        citiesNames,
        citiesIds,
        cities: info.Cities,
      }
    }
  } catch (err) {
    console.error(err)
  }

  return result
}

export const userInfo = JSON.parse(localStorage.getItem(credentialName))

const setUserInfo = data => {
  localStorage.setItem(credentialName, JSON.stringify(data))
  localStorage.setItem('cityId', JSON.stringify(data.cityId))
}

export const forgotPassword = ({ email }) =>
  axios
    .post(`${FORGOT_PASSWORD_URL}${email.toLowerCase()}`)
    .then(({ status, statusText }) => {
      if (status === 200) {
        showToast(
          'Nova senha enviada com sucesso! Enviamos para você um e-mail com sua nova senha',
        )
      } else {
        showToast(status, 'failure')
      }
    })
    .catch(err => {
      console.error(err)
      showToast('Ops, algo deu errado :(', 'failure')
    })
