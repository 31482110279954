import { useState, useEffect } from 'react'
import { brokersClient as prismicClient } from '@/services/prismic'

export const useContentFromCMS = contentType => {
  const [content, setContent] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const documents = await prismicClient.getAllByType(contentType)

        if (documents.length > 0) {
          const firstDocument = documents[0]

          setContent({
            version: firstDocument.data.version,
            content: firstDocument.data.content || [],
            rawContent: firstDocument.data,
          })
        } else {
          setContent(null)
        }
      } catch (err) {
        setError(err)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [contentType, setContent, setError, setLoading])

  return { content, loading, error }
}
