export default class AnimationsFactory {
  duration = 0

  constructor(duration) {
    this.duration = duration - 100 || 600
  }

  parallax(start, position) {
    const { newPosition } = animationCalc(start, position, this.duration)

    const endPercentage = 140

    if (newPosition < 100) return [100 - newPosition, newPosition - 100]

    if (newPosition > endPercentage)
      return [newPosition - endPercentage, endPercentage - newPosition]

    return [0, 0]
  }

  fadeInOut(start, position) {
    const { percentage } = animationCalc(start, position, this.duration)
    const endPercentage = 1.6

    if (percentage > endPercentage) return 2.2 - percentage

    return percentage
  }
}

function animationCalc(start, position) {
  const end = start + 600
  const movement = position - start
  const max = end - start
  const percentage = movement / max
  const newPosition = percentage * 100
  return { newPosition, percentage }
}
