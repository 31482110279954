import React, { useRef } from 'react'
import './footer.css'
import parse from 'html-react-parser'
import EEmovelDarkLogo from '../../../pages/Location/assets/svg/EEmovelDarkLogo'
import InstagramIcon from '../../../pages/Location/assets/svg/instagramIcon'
import YoutubeIcon from '../../../pages/Location/assets/svg/youtubeIcon'
import FacebookIcon from '../../../pages/Location/assets/svg/facebookIcon'
import LinkedinIcon from '../../../pages/Location/assets/svg/linkedinIcon'
import { subscribeToNewsletter } from '../../../services/news'

export default function HFooter({
  children,
  texts,
  newsletter = true,
  dark = true,
  product = 'landing',
}) {
  const ref = useRef()

  function subscribeSubmit(e) {
    e.preventDefault()
    const email = ref.current.value
    subscribeToNewsletter({ email })
  }

  return (
    <footer data-cy="HomeFooterSection" className="home-footer">
      <div data-cy="HomeFooterWrapperDiv" className="home-footer-wrapper">
        <div data-cy="HomeFooterLogoDiv" className="home-footer-logo">
          <EEmovelDarkLogo dark={dark} product={product} />
        </div>
        <nav data-cy="HomeFooterNav" className="home-footer-nav">
          {texts.footer.navLinks.map((item, index) => (
            <a
              key={item.title + index}
              data-cy={'HomeFooterNavLink' + index}
              href={item.link}
              rel="noopener noreferrer"
              target="_blank"
            >
              {item.title}
            </a>
          ))}
        </nav>
        {children}
        <div data-cy="HomeFooterSocialMediaDiv" className="home-footer-social">
          <h1
            data-cy="HomeFooterSocialMediaTitleH1"
            className="home-footer-social-title"
          >
            {texts.footer.contact.title}
          </h1>
          <div>
            {texts.footer.contact.list.map((item, index) => (
              <p
                key={'HomeFooterSocialMediaContactP' + index}
                data-cy={'HomeFooterSocialMediaContactP' + index}
              >
                {item}
              </p>
            ))}
          </div>
          <p
            data-cy="HomeFooterSocialAddressContactP"
            className="home-footer-social-address"
          >
            {parse(texts.footer.address)}
          </p>
          <div
            data-cy="HomeFooterSocialMediaIconsDiv"
            className="home-footer-social-medias"
          >
            <a
              data-cy="HomeFooterSocialMediaInstagramLink"
              href="https://www.facebook.com/EEmovel"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FacebookIcon />
            </a>
            <a
              data-cy="HomeFooterSocialMediaYoutubeLink"
              href="https://www.instagram.com/eemovel.com.br/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <InstagramIcon />
            </a>
            <a
              data-cy="HomeFooterSocialMediaFacebookLink"
              href="https://www.linkedin.com/company/eemovel"
              rel="noopener noreferrer"
              target="_blank"
            >
              <LinkedinIcon />
            </a>
            <a
              data-cy="HomeFooterSocialMediaLinkedinLink"
              href="https://www.youtube.com/c/EEmovel"
              rel="noopener noreferrer"
              target="_blank"
            >
              <YoutubeIcon />
            </a>
          </div>
        </div>
        {newsletter && (
          <div
            data-cy="HomeFooterSubscribeDiv"
            className="home-footer-subscribe"
          >
            <h1
              data-cy="HomeFooterSubscribeTitleH1"
              className="home-footer-subscribe-title"
            >
              {parse(texts.footer.subscribe)}
            </h1>

            <form
              data-cy="HomeFooterSubscribeFormDiv"
              className="home-footer-subscribe-register"
              onSubmit={subscribeSubmit}
            >
              <input
                required
                data-cy="HomeFooterSubscribeFormInput"
                type="email"
                name="email"
                placeholder="Digite seu e-mail"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                minLength={5}
                ref={ref}
              />
              <button data-cy="HomeFooterSubscribeFormButton" type="submit">
                {texts.global.send}
              </button>
            </form>
          </div>
        )}
      </div>
      <hr />
      <div data-cy="HomeFooterCopyRightDiv" className="copyright-advertise">
        <p>{texts.footer.copyright}</p>
      </div>
    </footer>
  )
}

function NavFooterLanding({ texts }) {
  return (
    <nav
      data-cy="HomeFooterNav"
      className="home-footer-nav footer-landing-solutions"
    >
      <h1 className="landing-footer-solutions__title">Soluções</h1>
      {texts.footer.navProducts?.map((item, index) => (
        <a
          key={item.title + index}
          data-cy={'HomeFooterNavLink' + index}
          href={item.link}
          rel="noopener noreferrer"
          target="_blank"
        >
          {item.title}
        </a>
      ))}
    </nav>
  )
}
export const HFooterOpt = {
  LandingNav: NavFooterLanding,
}
